import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import { usePlaidLink } from 'react-plaid-link';
import { useAuth } from '../../context/Auth';
import { sendClickEvent, sendEvent } from '../../utils/eventsService';

export default function ConnectPortfolioButton(props) {
	const { eventLocation, afterConnect, children } = props;
	const {
		authTokens,
		setAuthTokens,
		plaidLinkToken,
		setPlaidLinkToken,
		setLoadingPortfolio,
		getAuthHeader,
	} = useAuth();
	const [plaidSuccess, setPlaidSuccess] = useState(false);
	const { open, ready } = usePlaidLink({
		token: plaidLinkToken,
		onSuccess: (publicToken) => {
			setLoadingPortfolio(true);
			axios
				.post(
					'/api/plaid/token_exchange',
					{ public_token: publicToken },
					{ headers: getAuthHeader() }
				)
				.then(() => {
					sendEvent({
						gaName: 'connect_portfolio',
						gaParams: { method: 'plaid', failed: false },
					});
					if (authTokens?.is_verified) {
						if (!authTokens.is_verified.includes('portfolio')) {
							setAuthTokens({
								...authTokens,
								is_verified: [...authTokens.is_verified, 'portfolio'],
							});
						}
					} else {
						setAuthTokens({
							...authTokens,
							is_verified: ['portfolio'],
						});
					}
					setPlaidSuccess(true);
				})
				.then(() => {
					afterConnect();
				})
				.catch(() => {
					sendEvent({
						gaName: 'connect_portfolio',
						gaParams: { method: 'plaid', failed: true },
					});
				})
				.finally(() => {
					setLoadingPortfolio(false);
					setPlaidSuccess(true);
				});
		},
	});
	const handleConnectClick = useCallback(() => {
		if (!plaidSuccess) {
			open();
			sendClickEvent({
				element: 'connect_portfolio_button',
				event_location: eventLocation,
			});
		}
	}, [open, plaidSuccess, eventLocation]);
	useEffect(() => {
		if (authTokens && !plaidLinkToken) {
			axios
				.get('/api/plaid/create_link_token', { headers: getAuthHeader() })
				.then((res) => {
					setPlaidLinkToken(res?.data?.link_token || null);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (plaidSuccess) {
			setPlaidLinkToken(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [plaidSuccess]);
	return (
		<div
			role="button"
			onClick={handleConnectClick}
			tabIndex={0}
			// disabled={!authTokens || !ready}
			style={
				!authTokens || !ready ? { pointerEvents: 'none', opacity: '0.4' } : {}
			}
		>
			{children}
		</div>
	);
}
