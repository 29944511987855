import React, {
	lazy,
	Suspense,
	useState,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import * as Sentry from '@sentry/react';
import smoothscroll from 'smoothscroll-polyfill';
import useLocalStorage from 'use-local-storage';
import axios from 'axios';
import ReactGA4 from 'react-ga4';
import { clarity as Clarity } from 'react-microsoft-clarity';
import ReactFacebookPixel from 'react-facebook-pixel';
import RedditPixel from 'react-reddit-pixel';
import LinkedInTag from 'react-linkedin-insight';
import lifecycle from 'page-lifecycle';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from '../context/Auth';
import { WatchlistProvider } from '../context/WatchlistContext';
import { TrendsProvider } from '../context/TrendsContext';
import { UserSettingsProvider } from '../context/UserSettings';
import { TourProvider } from '../context/Tour';
import componentLoader from '../utils/componentLoader';
import SearchboxOption from './common/SearchboxOption';
import {
	sendEvent,
	initEventsManager,
	setEventServiceToken,
	sendClickEvent,
} from '../utils/eventsService';
import { addFirstModalView } from '../utils/infoModal';
import Spinner from './common/spinner/Spinner';
import useThunkReducer from '../hooks/useThunkReducer';
import {
	handleSetSearchBoxOptions,
	initialState,
} from '../reducers/indexReducer';
import {
	dynamicStateReducer,
	handleSetReducerState,
} from '../reducers/dynamicStateReducer';
import parseUnderscoreObjectToCamelCase from '../utils/parseUnderscoreObjectToCamelCase';
import { NotFoundPageProvider } from '../context/NotFoundPageContext';
import { EmbedProvider } from '../context/Embed';
import { NotificationsProvider } from '../context/NotificationsContext';
import { CheckUpdatesProvider } from '../context/useCheckUpdates';
import {
	getOptionsFromLeaderboard,
	loadTrendingLeaderboards,
} from '../utils/searchBox';
import { AuthQueueProvider } from '../context/AuthQueue';
import isEmbedRoute from '../utils/isEmbedRoute';
import OnboardingProvider from '../context/OnboardingContext';
import { PrerenderProvider } from '../context/PrerenderContext';
import { PaypalProvider } from '../context/PaypalProvider';
import { FollowListProvider } from '../context/FollowListContext';
import { InterviewProvider } from '../context/InterviewContext';
import { ABTestingProvider } from '../context/ABTestingManager';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// TBD - disable kickstart on iframes;
// console.log(window.location.pathname.includes('/embed/'));
// fetch trending companies
const loadCompanyList = async () =>
	axios
		.get('/api/company_list/')
		.then((res) => {
			let resultList;
			try {
				resultList = [...res.data.result.company_list].map((sublist) => {
					const currentSublist = {
						label: sublist.label,
						options: sublist.options.map((company) => {
							const label = (
								<SearchboxOption
									logo={company.logo}
									name={`${company.symbol} - ${company.company_name}`}
								/>
							);
							return { label, value: company };
						}),
					};
					return currentSublist;
				});
			} catch (error) {
				resultList = [];
			}
			return resultList;
		})
		.catch((error) => {
			console.log(error);
		});
// fetch metrics tooltips
const loadFinancialKeys = async () =>
	axios
		.get('/api/financial_keys_tooltips')
		.then((res) => {
			const { tooltips } = res.data.result;
			const financialKeys = parseUnderscoreObjectToCamelCase(tooltips);
			return financialKeys;
		})
		.catch((error) => {
			console.log(error);
		});

// Kick start the requests for faster response
const kickstartedPromise = isEmbedRoute
	? null
	: Promise.all([
			loadCompanyList(),
			loadFinancialKeys(),
			loadTrendingLeaderboards(),
	  ]);
const NotFoundPage = lazy(() =>
	componentLoader(() => import('./NotFoundPage'))
);
const DictionaryArticle = lazy(() =>
	componentLoader(() => import('./home/dictionary/DictionaryArticle'))
);
const PrivacyPolicy = lazy(() =>
	componentLoader(() => import('./home/privacy-policy/PrivacyPolicy'))
);
const TermsOfUse = lazy(() =>
	componentLoader(() => import('./home/terms-of-use/TermsOfUse'))
);
const VirtualTrading = lazy(() =>
	componentLoader(() => import('./home/landing-pages/VirtualTrading'))
);
const PortfolioTracker = lazy(() =>
	componentLoader(() => import('./home/landing-pages/PortfolioTracker'))
);
const MultiplesScreener = lazy(() =>
	componentLoader(() => import('./dashboard/analyze/Analyze'))
);
const Contact = lazy(() =>
	componentLoader(() => import('./home/contact/Contact'))
);
const ResetPassword = lazy(() =>
	componentLoader(() => import('./home/reset-password/ResetPassword'))
);
const Quote = lazy(() =>
	componentLoader(() => import('./dashboard/quote/Quote'))
);
const Challenges = lazy(() =>
	componentLoader(() => import('./dashboard/challenges/Challenges'))
);
const Challenge = lazy(() =>
	componentLoader(() => import('./dashboard/challenge/Challenge'))
);
const Discussion = lazy(() =>
	componentLoader(() => import('./dashboard/discussion/Discussion'))
);
const CompsFinder = lazy(() =>
	componentLoader(() => import('./dashboard/comps-finder/CompsFinder'))
);
const Profile = lazy(() =>
	componentLoader(() => import('./dashboard/profile/UserProfile'))
);
const Blog = lazy(() => componentLoader(() => import('./home/blog/Blog')));
const BlogArticle = lazy(() =>
	componentLoader(() => import('./home/blog/BlogArticle'))
);
const BlogEditor = lazy(() =>
	componentLoader(() => import('./home/blog/BlogEditor'))
);
const Home = lazy(() => componentLoader(() => import('./dashboard/home/Home')));
const Discover = lazy(() =>
	componentLoader(() => import('./dashboard/discover/Discover'))
);
const Screener = lazy(() =>
	componentLoader(() => import('./dashboard/screener/ScreenerNew'))
);
const AccountSettings = lazy(() =>
	componentLoader(() => import('./dashboard/account-settings/AccountSettings'))
);
const DictionaryMain = lazy(() =>
	componentLoader(() => import('./home/dictionary/DictionaryMain'))
);

const DictionaryEditor = lazy(() =>
	componentLoader(() => import('./home/dictionary/DictionaryEditor'))
);

const FaqEditor = lazy(() =>
	componentLoader(() => import('./common/faq/FaqEditor'))
);

const FundamentalsChartEmbed = lazy(() =>
	componentLoader(() => import('./dashboard/analyze/FundamentalsChartEmbed'))
);

const FundamentalsTableEmbed = lazy(() =>
	componentLoader(() => import('./dashboard/analyze/FundamentalsTableEmbed'))
);

const WidgetsContainer = lazy(() =>
	componentLoader(() => import('./home/widgets/WidgetsContainer'))
);

const Widgets = lazy(() =>
	componentLoader(() => import('./home/widgets/Widgets'))
);

const Widget = lazy(() =>
	componentLoader(() => import('./home/widgets/Widget'))
);

const QuoteForecastPriceTargetEmbed = lazy(() =>
	componentLoader(() =>
		import('./dashboard/quote/QuoteForecastPriceTargetEmbed')
	)
);

const ProfilePortfolioChartEmbed = lazy(() =>
	componentLoader(() =>
		import('./dashboard/profile/ProfilePortfolioChartEmbed')
	)
);

const SinglePost = lazy(() =>
	componentLoader(() => import('./dashboard/post/Post'))
);

const UserPortfolioEditor = lazy(() =>
	componentLoader(() => import('./common/user/UserPortfolioEditor'))
);

const EmployeePinPost = lazy(() =>
	componentLoader(() => import('./dashboard/employee/EmpolyeePinPost'))
);

const EmployeeTriggerTasks = lazy(() =>
	componentLoader(() => import('./dashboard/employee/EmployeeTriggerTasks'))
);

const EmployeeWeeklyGainers = lazy(() =>
	componentLoader(() => import('./dashboard/employee/EmployeeWeeklyGainers'))
);

const EmployeeDeletePost = lazy(() =>
	componentLoader(() => import('./dashboard/employee/EmpolyeeDeletePost'))
);

const EmployeeNewFeatures = lazy(() =>
	componentLoader(() => import('./dashboard/employee/EmployeeNewFeatures'))
);

const EmployeeDeleteUser = lazy(() =>
	componentLoader(() => import('./dashboard/employee/EmployeeDeleteUser'))
);

const EmployeeCreateWeeklyReport = lazy(() =>
	componentLoader(() =>
		import('./dashboard/employee/EmployeeCreateWeeklyReport')
	)
);

const EmployeeAcademy = lazy(() =>
	componentLoader(() => import('./dashboard/employee/EmployeeAcademy'))
);

const MainLeaderboard = lazy(() =>
	componentLoader(() => import('./dashboard/leaderboard/MainLeaderboard'))
);

const Academy = lazy(() =>
	componentLoader(() => import('./dashboard/academy/Academy'))
);

const AcademyHome = lazy(() =>
	componentLoader(() => import('./dashboard/academy/AcademyHome'))
);

const AcademySkill = lazy(() =>
	componentLoader(() => import('./dashboard/academy/AcademySkill'))
);

const AcademySkillPreviewPage = lazy(() =>
	componentLoader(() => import('./dashboard/academy/AcademySkillPreviewPage'))
);

ReactGA4.initialize('AW-10780603335', {
	// Use cookieDomain to disable hits from localhost https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#cookieDomain
	gaOptions: { cookieDomain: 'jika.io' },
});
Clarity.init('eqgizr2al9');
ReactFacebookPixel.init('1079930366096766');
RedditPixel.init('t2_f4d5655j');
LinkedInTag.init('3518018');
smoothscroll.polyfill();

export default function Index() {
	const [state, thunkDispatch] = useThunkReducer(
		dynamicStateReducer,
		initialState
	);
	const {
		suggestedSearchBoxOptions,
		userSearchBoxDefaultOptions,
		stockSearchBoxDefaultOptions,
		multiSearchBoxDefaultOptions,
		financialKeysTooltips,
		leaderboard,
	} = useMemo(() => state, [state]);

	const [isEmbed, setIsEmbed] = useState(false);
	const [isEmbedLoading, setIsEmbedLoading] = useState(true);
	const [authTokens, setAuthTokens] = useLocalStorage('tokens', null);
	const [showAcademyWelcomeModal, setShowAcademyWelcomeModal] = useState(false);
	const [widgetAuthTokens, setWidgetAuthTokens] = useLocalStorage(
		'widgetTokens',
		null
	);
	const [plaidLinkToken, setPlaidLinkToken] = useState(null);
	const [loadingPortfolio, setLoadingPortfolio] = useState(false);
	const [userSettings, setUserSettings] = useLocalStorage('userSettings', {});
	const [authModalType, setAuthModalType] = useState();
	const [afterOnboarding, setAfterOnboarding] = useState();
	const [isToured, setIsToured] = useState(false);
	const [paywallModalType, setPaywallModalType] = useState();
	const [PaywallModalEventLocation, setPaywallModalEventLocation] = useState();
	const [questionnaireAnswers, setQuestionnaireAnswers] = useState([]);
	const [authModalNoClose, setAuthModalNoClose] = useState(false);

	const handleQuestionnaireAnswer = useCallback(
		(id, subId, answer) => {
			setQuestionnaireAnswers((currentState) => [
				...currentState,
				{ id, subId, answer },
			]);
		},
		[setQuestionnaireAnswers]
	);
	// const [isTokenPanding, setIsTokenPanding] = useLocalStorage('isTokenPanding',false);

	const setAllIndexReducer = useCallback(
		(newState) => {
			handleSetReducerState(thunkDispatch, newState);
		},
		[thunkDispatch]
	);

	useEffect(() => {
		if (!isEmbedRoute) {
			document.body.style.overflow = 'auto';
			document.body.style.overflowX = 'auto';
			document.body.style.overflowY = 'auto';
		} else {
			document.body.style.overflow = 'hidden';
		}
	}, [loadingPortfolio]);

	const setLeaderboard = useCallback(
		(value) => {
			setAllIndexReducer({ leaderboard: value });
		},
		[setAllIndexReducer]
	);

	const setSuggestedSearchBoxOptions = useCallback(
		(value) => {
			handleSetSearchBoxOptions(thunkDispatch, (currentState) => {
				const newSearchBoxOptions = [...currentState];
				let isSuggested;
				newSearchBoxOptions.some((item, index) => {
					if (item.label === 'suggested') {
						newSearchBoxOptions[index].options = value;
						isSuggested = true;
					}
					return item.label === 'suggested';
				});
				if (!isSuggested) {
					newSearchBoxOptions.unshift({ label: 'suggested', options: value });
				}
				return newSearchBoxOptions;
			});
		},
		[thunkDispatch]
	);

	const getAuthHeader = useCallback(() => {
		const headers = authTokens
			? { Authorization: `Bearer ${authTokens.token}` }
			: {};
		return headers;
	}, [authTokens]);

	const getWidgetAuthHeader = useCallback(() => {
		const headers = widgetAuthTokens
			? { Authorization: `Bearer ${widgetAuthTokens.token}` }
			: {};
		return headers;
	}, [widgetAuthTokens]);

	const setTokens = useCallback(
		(data) => {
			if (!data) {
				// In case we want to revoke tokens - i.e. logout
				axios
					.delete('/api/tokens', {
						headers: {
							Authorization: `Bearer ${authTokens.token}`,
						},
					})
					.then(() => {
						setAuthTokens(null);
					})
					.catch((error) => console.log(error));
			} else {
				// In case we want to save tokens - i.e. login / refresh tokens
				setAuthTokens(data);
			}
		},
		[authTokens, setAuthTokens]
	);

	const setSettings = useCallback(
		(data) => {
			const tempUserSettings = { ...userSettings, ...data };
			setUserSettings(tempUserSettings);
		},
		[userSettings, setUserSettings]
	);

	// for multiple requests
	let isRefreshing = false;
	let failedQueue = [];

	const processQueue = (error, token = null) => {
		failedQueue.forEach((prom) => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve(token);
			}
		});
		failedQueue = [];
	};

	axios.interceptors.response.use(
		(res) => res,
		(error) => {
			const originalRequest = error.config;
			const tokens = JSON.parse(localStorage.getItem('tokens'));

			// in case signin failed
			if (
				error?.response?.status === 401 &&
				(originalRequest.url === '/api/tokens' ||
					originalRequest.url.includes('/api/auth')) &&
				originalRequest.method === 'get'
			) {
				return Promise.reject(error);
			}

			// in case user is not authenticated
			if (error?.response?.status === 401 && tokens === null) {
				setAuthModalType('signup');
				return Promise.reject(error);
			}

			// in case refresh token failed
			if (
				error?.response?.status === 401 &&
				originalRequest.url === '/api/tokens' &&
				originalRequest.method === 'post'
			) {
				setAuthTokens(null);
				setAuthModalType('signin');
				return Promise.reject(error);
			}

			// in case refresh token is needed
			if (error?.response?.status === 401 && !originalRequest._retry) {
				if (isRefreshing) {
					return new Promise((resolve, reject) => {
						// push the request to the queue, stop pending when processQueue will take place.
						failedQueue.push({ resolve, reject });
					})
						.then((token) => {
							originalRequest.headers.Authorization = `Bearer ${token}`;
							return axios(originalRequest);
						})
						.catch((err) => {
							console.log(err);
							return Promise.reject(err);
						});
				}

				originalRequest._retry = true;
				isRefreshing = true;

				const currentTokens = JSON.parse(localStorage.getItem('tokens'));
				return new Promise((resolve, reject) => {
					axios
						.post('/api/tokens', {
							token: currentTokens.token,
							refresh_token: currentTokens.refresh_token,
						})
						.then(({ data }) => {
							setTokens(data);
							originalRequest.headers.Authorization = `Bearer ${data.token}`;
							processQueue(null, data.token);
							resolve(axios(originalRequest));
						})
						.catch((err) => {
							processQueue(err, null);
							reject(err);
						})
						.finally(() => {
							isRefreshing = false;
						});
				});
			}

			return Promise.reject(error);
		}
	);

	useEffect(() => {
		// adding the first modal view object to a Local Storage
		addFirstModalView();

		initEventsManager();
		lifecycle.addEventListener('statechange', (event) => {
			const { newState } = event;
			if (newState === 'hidden' || newState === 'terminated') {
				const data = {
					gaName: 'state_change',
					gaParams: { new_state: newState },
				};
				sendEvent(data);
			}
		});
		initEventsManager();
		const trackElementListener = (e) =>
			e.composedPath().forEach((element) => {
				if (element.id && element.id.includes('track-element ')) {
					const elementName = element.id.replace('track-element ', '');
					sendClickEvent({
						element: elementName,
					});
				}
			});
		window.addEventListener('click', trackElementListener);
		return () => {
			window.removeEventListener('click', trackElementListener);
		};
		// eslint-disable-next-line
	}, []);

	// update token on event service
	useEffect(() => {
		if (authTokens && authTokens.token) {
			setEventServiceToken(authTokens.token);
			Sentry.setTag('loggedIn', true);
			Sentry.setUser({
				username: authTokens.user_name,
			});
			ReactGA4.set({
				userId: authTokens.user_id,
			});
		} else if (widgetAuthTokens && widgetAuthTokens.token) {
			setEventServiceToken(widgetAuthTokens.token);
			Sentry.setTag('loggedIn', true);
			Sentry.setUser({
				username: widgetAuthTokens.full_name,
			});
			ReactGA4.set({
				userId: widgetAuthTokens.user_id,
			});
		} else {
			setEventServiceToken(null);
			Sentry.setTag('loggedIn', null);
			Sentry.setUser(null);
			ReactGA4.set({
				userId: null,
			});
		}
	}, [authTokens, widgetAuthTokens]);

	useEffect(() => {
		kickstartedPromise?.then((res) => {
			const tempUsersSearchBoxDefaultOptions = res[2]
				? getOptionsFromLeaderboard(res[2])
				: { label: 'member', options: [] };
			const newState = {
				multiSearchBoxDefaultOptions: [
					tempUsersSearchBoxDefaultOptions,
					...(res[0] || []),
				],
				stockSearchBoxDefaultOptions: [...(res[0] || [])],
				userSearchBoxDefaultOptions: [tempUsersSearchBoxDefaultOptions],
				financialKeysTooltips: res[1] || {},
				leaderboard: res[2] || [],
			};
			setAllIndexReducer(newState);
		});
		// eslint-disable-next-line
	}, []);
	// const [updateInterval, setUpdateInterval] = useState(null);
	// useEffect(() => {
	// 	if (isInitialNotifs.current) {
	// 		isInitialNotifs.current = false;
	// 	}
	// 	if (updateInterval) {
	// 		clearInterval(updateInterval);
	// 	}
	// 	setUpdateInterval(
	// 		setInterval(() => {
	// 			if (authTokens) {
	// 				axios
	// 					.get('/api/stream/notifications/user?checkUpdate=true', {
	// 						headers: getAuthHeader(),
	// 					})
	// 					.then((res) => {
	// 						setLastNotificationId(res.data.last_notification);
	// 						if (lastNotificationId === 0 && notifications.length > 0) {
	// 							setNotifications(res.data.user.results);
	// 						} else if (Array.isArray(res.data.user.results)) {
	// 							setNotifications((currentState) => [
	// 								...currentState,
	// 								...(res.data.user.results || []),
	// 							]);
	// 							setUnseenCount(res.data.user.unseen);
	// 						}
	// 					});
	// 			}
	// 		}, 5000)
	// 	);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [authTokens]);

	return (
		<AuthProvider
			value={useMemo(
				() => ({
					authTokens,
					setAuthTokens: setTokens,
					plaidLinkToken,
					setPlaidLinkToken,
					authModalType,
					setAuthModalType,
					getAuthHeader,
					setLoadingPortfolio,
					loadingPortfolio,
					afterOnboarding,
					setAfterOnboarding,
					widgetAuthTokens,
					setWidgetAuthTokens,
					getWidgetAuthHeader,
					handleQuestionnaireAnswer,
					questionnaireAnswers,
					setQuestionnaireAnswers,
					authModalNoClose,
					setAuthModalNoClose,
					showAcademyWelcomeModal,
					setShowAcademyWelcomeModal,
				}),
				[
					authTokens,
					plaidLinkToken,
					authModalType,
					getAuthHeader,
					setTokens,
					setLoadingPortfolio,
					loadingPortfolio,
					afterOnboarding,
					setAfterOnboarding,
					widgetAuthTokens,
					setWidgetAuthTokens,
					getWidgetAuthHeader,
					handleQuestionnaireAnswer,
					questionnaireAnswers,
					setQuestionnaireAnswers,
					authModalNoClose,
					setAuthModalNoClose,
					showAcademyWelcomeModal,
					setShowAcademyWelcomeModal,
				]
			)}
		>
			<PaypalProvider>
				<AuthQueueProvider>
					<BrowserRouter basename="/">
						<div id={`main-wrapper${isEmbed ? '-embed' : ''}`}>
							<UserSettingsProvider
								value={useMemo(
									() => ({
										userSettings,
										setUserSettings: setSettings,
									}),
									[userSettings, setSettings]
								)}
							>
								<NotFoundPageProvider>
									<TrendsProvider
										value={useMemo(
											() => ({
												PaywallModalEventLocation,
												paywallModalType,
												setPaywallModalType,
												setPaywallModalEventLocation,
												suggestedSearchBoxOptions,
												userSearchBoxDefaultOptions,
												stockSearchBoxDefaultOptions,
												multiSearchBoxDefaultOptions,
												financialKeysTooltips,
												leaderboard,
												setLeaderboard,
												setSuggestedSearchBoxOptions,
											}),
											[
												PaywallModalEventLocation,
												paywallModalType,
												setPaywallModalType,
												setPaywallModalEventLocation,
												suggestedSearchBoxOptions,
												userSearchBoxDefaultOptions,
												stockSearchBoxDefaultOptions,
												multiSearchBoxDefaultOptions,
												financialKeysTooltips,
												leaderboard,
												setLeaderboard,
												setSuggestedSearchBoxOptions,
											]
										)}
									>
										<EmbedProvider
											value={useMemo(
												() => ({
													isEmbed,
													setIsEmbed,
													isEmbedLoading,
													setIsEmbedLoading,
												}),
												[isEmbed, isEmbedLoading]
											)}
										>
											<TourProvider
												value={useMemo(
													() => ({ isToured, setIsToured }),
													[isToured]
												)}
											>
												<OnboardingProvider>
													<WatchlistProvider>
														<CheckUpdatesProvider>
															<NotificationsProvider>
																<FollowListProvider>
																	<ABTestingProvider>
																		<InterviewProvider>
																			<Suspense
																				fallback={<Spinner isLoading />}
																			>
																				<SentryRoutes>
																					<Route
																						path="/"
																						exact
																						element={
																							<PrerenderProvider>
																								<Home />
																							</PrerenderProvider>
																						}
																					/>
																					{/* endpoint for email innovators campaign, currently not used fro redirecting to home */}
																					<Route
																						path="/innovators"
																						exact
																						element={
																							<Navigate
																								to={{
																									pathname: '/',
																								}}
																								replace
																							/>
																						}
																					/>
																					<Route
																						path="/onepager"
																						exact
																						element={
																							<Navigate
																								to={{
																									pathname: '/',
																								}}
																								replace
																							/>
																						}
																					/>
																					<Route
																						path="/discover"
																						exact
																						element={<Discover />}
																					/>
																					<Route
																						path="/privacypolicy"
																						element={<PrivacyPolicy />}
																					/>
																					<Route
																						path="/termsofuse"
																						element={<TermsOfUse />}
																					/>
																					<Route
																						path="/savemyspot"
																						element={
																							<Navigate
																								to={{
																									pathname: '/',
																								}}
																								replace
																							/>
																						}
																					/>
																					<Route
																						path="/contact"
																						element={<Contact />}
																					/>
																					<Route
																						path="/signin"
																						element={
																							<Navigate
																								to={{
																									pathname: '/',
																								}}
																								replace
																							/>
																						}
																					/>
																					<Route
																						path="/signup"
																						element={
																							<Navigate
																								to={{
																									pathname: '/',
																								}}
																								replace
																							/>
																						}
																					/>
																					<Route
																						path="/reset-password"
																						element={<ResetPassword />}
																					/>
																					<Route
																						path="/blog"
																						exact
																						element={
																							<PrerenderProvider>
																								<Blog />
																							</PrerenderProvider>
																						}
																					/>
																					<Route
																						path="/blog/:articleTitle"
																						exact
																					>
																						<Route
																							path=""
																							element={
																								<PrerenderProvider>
																									<BlogArticle />
																								</PrerenderProvider>
																							}
																						/>
																						<Route
																							path=":articlePage"
																							element={
																								<PrerenderProvider>
																									<BlogArticle />
																								</PrerenderProvider>
																							}
																						/>
																					</Route>
																					<Route
																						path="/dictionary"
																						exact
																						element={<DictionaryMain />}
																					/>
																					<Route
																						path="/dictionary/:dictioanaryArticle"
																						exact
																						element={<DictionaryArticle />}
																					/>
																					<Route
																						path="/blogeditor"
																						element={
																							<PrivateRoute authPrivilege="blogEditor">
																								<BlogEditor />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/tools/multiples"
																						exact
																						element={<MultiplesScreener />}
																					/>
																					<Route
																						path="/tools/multiples/:symbols"
																						exact
																						element={<MultiplesScreener />}
																					/>
																					<Route
																						path="/tools/multiples/:symbols/:companyNames"
																						exact
																						element={<MultiplesScreener />}
																					/>
																					<Route
																						path="/tools/multiples/:symbols/:companyNames/:financialKey"
																						exact
																						element={<MultiplesScreener />}
																					/>
																					<Route
																						path="/tools/compsfinder"
																						exact
																						element={<CompsFinder />}
																					/>
																					<Route
																						path="/tools/compsfinder/:symbol"
																						exact
																						element={<CompsFinder />}
																					/>
																					<Route path="/u/:userName" exact>
																						<Route
																							path=":selectedTab"
																							element={<Profile />}
																						/>
																						<Route
																							path=""
																							element={<Profile />}
																						/>
																					</Route>
																					<Route
																						path="/tools/screener"
																						exact
																						element={
																							<PrerenderProvider>
																								<Screener />
																							</PrerenderProvider>
																						}
																					/>
																					<Route
																						path="/tools/screener/:screenerKey"
																						exact
																						element={
																							<PrerenderProvider>
																								<Screener />
																							</PrerenderProvider>
																						}
																					/>
																					<Route
																						path="/accountsettings"
																						exact
																						element={
																							authTokens ? (
																								<AccountSettings />
																							) : (
																								<Navigate
																									to={{ pathname: '/' }}
																									replace
																								/>
																							)
																						}
																					/>
																					<Route
																						path="/landing/product-hunt"
																						element={
																							<Navigate
																								to={{
																									pathname: '/',
																								}}
																								replace
																							/>
																						}
																					/>
																					<Route
																						path="/landing/virtual-trading"
																						element={<VirtualTrading />}
																					/>
																					<Route
																						path="/landing/portfolio-tracker"
																						element={<PortfolioTracker />}
																					/>
																					<Route path="/quote/:symbol">
																						<Route
																							path=":query/:subQuery/:secondSubQuery"
																							element={
																								<PrerenderProvider>
																									<Quote />
																								</PrerenderProvider>
																							}
																						/>
																						<Route
																							path=":query/:subQuery"
																							element={
																								<PrerenderProvider>
																									<Quote />
																								</PrerenderProvider>
																							}
																						/>
																						<Route
																							path=":query"
																							element={
																								<PrerenderProvider>
																									<Quote />
																								</PrerenderProvider>
																							}
																						/>
																						<Route
																							path=""
																							element={
																								<PrerenderProvider>
																									<Quote />
																								</PrerenderProvider>
																							}
																						/>
																					</Route>
																					<Route
																						path="/challenge/:challengeId"
																						element={<Challenge />}
																					/>
																					<Route
																						path="/challenges"
																						element={<Challenges />}
																					/>
																					<Route
																						path="/discussion/:challengeId"
																						element={
																							<PrivateRoute>
																								<Discussion />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/dictionary/edit"
																						element={
																							<PrivateRoute authPrivilege="blogEditor">
																								<DictionaryEditor />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/faq/editor"
																						element={
																							<PrivateRoute authPrivilege="blogEditor">
																								<FaqEditor />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/widgets"
																						element={<WidgetsContainer />}
																					>
																						<Route
																							path=":widgetType"
																							element={<Widget />}
																						/>
																						<Route
																							path=""
																							element={<Widgets />}
																						/>
																					</Route>
																					<Route
																						path="/embed/fundamentals-chart"
																						element={<FundamentalsChartEmbed />}
																					/>
																					<Route
																						path="/embed/fundamentals-table"
																						element={<FundamentalsTableEmbed />}
																					/>
																					<Route
																						path="/embed/forecast-price-target"
																						element={
																							<QuoteForecastPriceTargetEmbed />
																						}
																					/>
																					<Route
																						path="/embed/user-portfolio"
																						element={
																							<ProfilePortfolioChartEmbed />
																						}
																					/>
																					<Route
																						path="/post/:postId"
																						element={
																							<PrerenderProvider>
																								<SinglePost />
																							</PrerenderProvider>
																						}
																					/>
																					<Route
																						path="/leaderboard"
																						element={<MainLeaderboard />}
																					/>
																					<Route
																						path="/academy"
																						element={<Academy />}
																					>
																						<Route
																							path=""
																							element={<AcademyHome />}
																						/>
																						<Route
																							path="/academy/:skillName"
																							element={<AcademySkill />}
																						/>
																						<Route
																							path="/academy/:skillName/:skillLesson"
																							element={<AcademySkill />}
																						/>

																						<Route
																							path="/academy/preview/:skillName"
																							element={
																								<AcademySkillPreviewPage />
																							}
																						/>
																					</Route>
																					<Route
																						path="/portfolio/editor"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<UserPortfolioEditor />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/pin"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeePinPost />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/trigger-task"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeeTriggerTasks />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/weekly-gainers"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeeWeeklyGainers />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/delete-posts"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeeDeletePost />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/features"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeeNewFeatures />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/delete-user"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeeDeleteUser />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/create-weekly-report"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeeCreateWeeklyReport />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="/employee/academy"
																						element={
																							<PrivateRoute authPrivilege="employee">
																								<EmployeeAcademy />
																							</PrivateRoute>
																						}
																					/>
																					<Route
																						path="*"
																						element={<NotFoundPage />}
																					/>
																				</SentryRoutes>
																			</Suspense>
																		</InterviewProvider>
																	</ABTestingProvider>
																</FollowListProvider>
															</NotificationsProvider>
														</CheckUpdatesProvider>
													</WatchlistProvider>
												</OnboardingProvider>
											</TourProvider>
										</EmbedProvider>
									</TrendsProvider>
								</NotFoundPageProvider>
							</UserSettingsProvider>
						</div>
					</BrowserRouter>
				</AuthQueueProvider>
			</PaypalProvider>
		</AuthProvider>
	);
}
