import React from 'react';
import PreConnectPortfolioButton from '../components/common/PreConnectPortfolioButton';

const rawChecklistSteps = [
	{
		verified: true,
		title: 'Create an Account',
	},
	{
		verified: 'portfolio',
		verifiedRequired: '$ne__initial_cash',
		title: 'Add a Portfolio',
		text: 'Elevate your investing game by presenting your picks, gaining diverse viewpoints, and initiating portfolio-centered conversations.',
		buttonTitle: (
			<PreConnectPortfolioButton
				variant="primary"
				label={<small>Add a Portfolio</small>}
				className="py-1 mb-2"
				eventLocation="onboarding-task-button"
			/>
		),
	},
	{
		verified: 'portfolio',
		verifiedRequired: 'initial_cash',
		title: 'Add Initial Cash Deposit',
		text: 'Elevate your investing game by presenting your picks, gaining diverse viewpoints, and initiating portfolio-centered conversations.',
		buttonTitle: (
			<PreConnectPortfolioButton
				variant="primary"
				label={<small>Add Initial Cash Deposit</small>}
				className="py-1 mb-2"
				stage="cashDeposit"
				eventLocation="onboarding-task-button"
			/>
		),
	},
	{
		verified: 'transaction',
		title: 'Make Your First Transaction',
		text: 'Turn intention into action. Dive into the world of investing with our tools and become an active part of our community.',
		buttonTitle: 'Show Me How',
	},
	{
		verified: 'follows',
		title: 'Follow an Investor',
		text: 'Identify and connect with like-minded investors. Receive alerts when they post, comment, or trade.',
		buttonTitle: 'Show Me How',
	},
	{
		verified: 'watchlist',
		title: 'Follow a Company',
		text: 'Add companies to your watchlist to receive alerts when they are mentioned in the community.',
		buttonTitle: 'Show Me How',
	},
];

// eslint-disable-next-line import/prefer-default-export
export { rawChecklistSteps };
