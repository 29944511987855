import React from 'react';
import axios from 'axios';
import SearchboxOption from '../components/common/SearchboxOption';

const loadMultiListOptions = (
	newInputValue,
	callback,
	getAuthHeader,
	limit
) => {
	if (newInputValue !== '') {
		const limitArg = Object.keys(limit).map(
			(key) => `${key}_limit=${limit[key]}&`
		);
		const url = `/api/multi_list/${newInputValue}?${limitArg}`;
		axios
			.get(url, { headers: getAuthHeader() })
			.then((res) => {
				const { multi_list: multiList } = res.data.result;
				callback(
					Object.keys(multiList)
						.sort((a, b) => {
							if (a === 'member') {
								return -1;
							}
							if (a === 'company') {
								return b === 'member' ? 1 : -1;
							}
							return 0;
						})
						.reduce((acc, key) => {
							// eslint-disable-next-line no-unused-vars
							let keys = [{ label: key, key }];
							if (key === 'member') {
								keys[0].key = '';
							}
							if (key === 'company') {
								keys = [
									{ label: 'Stock Overview', key: 'overview' },
									{ label: 'Stock Financials', key: 'financials' },
									{ label: 'Stock Dividend', key: 'dividend' },
									{ label: 'Stock Forecast', key: 'forecast' },
								];
							}
							keys.forEach(({ label, key: currentKey }) => {
								const options = multiList[key].map((item) => {
									const logo = item.logo || item.avatar_urls?.avatar_url_s;
									const name =
										item.user_name ||
										(item.company_name &&
											`${item.symbol} - ${item.company_name}`);
									return {
										label: (
											<SearchboxOption
												logo={logo}
												name={name}
												key={`${currentKey}__${item.symbol}`}
												dataNavOption={currentKey}
											/>
										),
										value: item,
									};
								});
								acc.push({
									label,
									options,
								});
							});

							return acc;
						}, [])
				);
			})
			.catch((error) => {
				console.log(error);
			});
	}
};

const getOptionsFromLeaderboard = (leaderboard) => ({
	label: 'Member',
	options:
		leaderboard?.map((leaderboardItem) => ({
			label: (
				<SearchboxOption
					logo={leaderboardItem.avatar_urls?.avatar_url_s}
					name={leaderboardItem.userName}
				/>
			),
			value: {
				user_name: leaderboardItem.userName,
				logo: leaderboardItem.avatar_urls?.avatar_url_s,
			},
		})) || {},
});

const loadTrendingLeaderboards = (limit = null) =>
	axios
		.get(`/api/leaderboard/jika?type=trending&limit=${limit || 5}&shuffle=true`)
		.then((res) => res.data.leaderboard_members)
		.catch((error) => {
			console.log(error);
		});

export {
	loadMultiListOptions,
	getOptionsFromLeaderboard,
	loadTrendingLeaderboards,
};
