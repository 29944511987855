import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import debounce from '../../utils/debounce';
import SearchboxOption from './SearchboxOption';
import { useTrends } from '../../context/TrendsContext';
import { sendEvent } from '../../utils/eventsService';
import MainSearchboxBase from './MainSearchboxBase';

export default function MainSearchbox({
	tabNavigation,
	hideSearchIcon,
	isFocused,
	notEtf,
	returnDetailedObject,
	suggstedCompanies,
	className,
	searchboxRef,
	onChange,
	placeholder = 'Enter symbol or company',
	isMulti = false,
	styles = {},
	value = '',
	autoFocus,
	noReset = false,
	refreshInputValue,
	onClear = false,
	searchboxOptionsLabel,
	exclude,
}) {
	const {
		stockSearchBoxDefaultOptions: stockSearchBoxDefaultOptionsTrends = [],
		suggestedSearchBoxOptions = [],
		setSuggestedSearchBoxOptions,
	} = useTrends();
	const navigate = useNavigate();
	const [selectKey, setSelectKey] = useState(`${Date.now()}`);
	const loadOptions = useCallback(
		(inputValue, callback) => {
			if (inputValue !== '') {
				const url = `${
					notEtf
						? `/api/company_list/${inputValue}/not-etf`
						: `/api/company_list/${inputValue}`
				}?${exclude ? `exclude=${exclude}&` : ''}`;
				axios
					.get(url)
					.then((res) => {
						callback(
							res.data.result.company_list.map((company) => {
								const label = (
									<SearchboxOption
										logo={company.logo}
										name={`${company.symbol} - ${company.company_name}`}
									/>
								);
								return {
									label,
									value: returnDetailedObject ? company : company.symbol,
								};
							})
						);
					})
					.catch((error) => {
						console.log(error);
					});
			}
		},
		[notEtf, returnDetailedObject, exclude]
	);

	// Debounce is used to send one request after 300ms, to save the requests that would have been sent while typing,
	// meaning, typing AAPL would send one request instead, "A", "AA", "AAP", "AAPL".
	const debouncedLoadOptions = debounce(loadOptions, 300);

	useEffect(() => {
		// useEffect for adding suggested companies after you get the company data
		const res = [];
		const excludeList = exclude?.split(',') || [];
		if (suggstedCompanies) {
			suggstedCompanies.forEach((company) => {
				if (!excludeList.includes(company.symbol)) {
					const label = (
						<SearchboxOption
							logo={company.logo}
							name={`${company.symbol} - ${company.company_name}`}
						/>
					);
					res.push({
						label,
						value: returnDetailedObject ? company : company.symbol,
					});
				}
			});
			setSuggestedSearchBoxOptions(res);
		}
		// Reset suggestedCompanies on unMount
		return () => {
			if (!noReset) {
				setSuggestedSearchBoxOptions([]);
			}
		};
		// eslint-disable-next-line
	}, [suggstedCompanies, exclude]);
	const goToQuote = useCallback(
		(symbolsValue) => {
			const eventObject = {
				gaName: 'search',
				gaParams: {
					type: 'company',
					search_term: symbolsValue,
					symbols: [symbolsValue],
					tabNavigation,
				},
				fbEvent: 'Search',
				fbParams: {
					content_ids: [symbolsValue],
				},
				rdtEvent: 'Search',
				linkedInConversionId: '8183506',
			};
			let url = `/quote/${symbolsValue}`;
			if (tabNavigation) {
				eventObject.gaParams.navOption = tabNavigation;
				url = `${url}/${tabNavigation}`;
			}
			sendEvent(eventObject);
			navigate(url);
		},
		[navigate, tabNavigation]
	);

	const stockSearchBoxDefaultOptions = useMemo(() => {
		const excludeList = exclude?.split(',') || [];
		return stockSearchBoxDefaultOptionsTrends.map(
			(stockSearchBoxDefaultOptionsTrendsItem) => {
				const tempOptions = [];
				stockSearchBoxDefaultOptionsTrendsItem.options.forEach((option) => {
					if (!excludeList.includes(option.value.symbol)) {
						tempOptions.push(option);
					}
				});
				return {
					label:
						searchboxOptionsLabel ||
						stockSearchBoxDefaultOptionsTrendsItem.label,
					options: tempOptions,
				};
			}
		);
	}, [exclude, stockSearchBoxDefaultOptionsTrends, searchboxOptionsLabel]);
	return (
		<MainSearchboxBase
			hideSearchIcon={hideSearchIcon}
			autoFocus={autoFocus}
			isFocused={isFocused}
			isMulti={isMulti}
			returnDetailedObject={returnDetailedObject}
			className={className}
			searchboxRef={searchboxRef}
			searchBoxName="symbolsAndCompanies"
			searchBoxOptions={
				suggestedSearchBoxOptions[0]?.options?.length
					? suggestedSearchBoxOptions
					: stockSearchBoxDefaultOptions
			}
			loadOptions={debouncedLoadOptions}
			onChange={(event) => {
				if (onClear || event) {
					if (onChange) {
						onChange(isMulti === true ? event : event?.value);
					} else if (event?.value) {
						goToQuote(event.value);
					}
					if (refreshInputValue) {
						setSelectKey(`${Date.now()}`);
					}
				}
			}}
			placeholder={placeholder}
			styles={styles}
			value={value || ''}
			valueKey="symbol"
			key={selectKey}
		/>
	);
}
