import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useScreen } from '../../context/UseScreenContext';
// import ShowMore from 'react-show-more';

export default function InfoTooltip(props) {
	const {
		name,
		info,
		title,
		placement,
		showIcon,
		className,
		style,
		customTooltip,
	} = props;
	const { isComputer } = useScreen();
	return info || customTooltip ? (
		<OverlayTrigger
			key="top"
			placement={placement && placement}
			rootClose
			rootCloseEvent={isComputer ? 'mousedown' : 'click'}
			trigger={isComputer ? ['hover', 'focus'] : ['click', 'focus']}
			overlay={
				customTooltip || (
					<Tooltip id="tooltip-top" name={name}>
						{/* <ShowMore lines={3}> */}
						{info}
						{/* </ShowMore> */}
					</Tooltip>
				)
			}
		>
			<span
				name={name}
				style={{ ...style, cursor: 'pointer' }}
				className={`align-items-center d-flex${
					className ? ` ${className}` : ''
				}`}
			>
				{title}
				{showIcon && (
					<i
						name={name}
						className="la la-question-circle ml-1"
						style={{ fontSize: '20px' }}
					/>
				)}
			</span>
		</OverlayTrigger>
	) : (
		<span
			className={`align-items-center d-flex${className ? ` ${className}` : ''}`}
		>
			{title}
			{showIcon && (
				<i
					style={{ cursor: 'pointer' }}
					className="la la-question-circle ml-1"
				/>
			)}
		</span>
	);
}
