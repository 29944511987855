import React from 'react';
import { Link } from 'react-router-dom';
import discussionIcon from '../icons/discussion-icon.png';
import screenerIcon from '../icons/screener-icon.png';
import teslaIcon from '../icons/tesla-icon.png';
import { sendClickEvent } from '../../../../utils/eventsService';

const items = [
	{
		icon: screenerIcon,
		title: 'Find Investment Ideas Using Stock Screener',
		text: 'Use the stock screener to find stocks that match your investment criteria',
		linkTitle: 'Open the Stock Screener',
		linkTo: '/tools/screener',
		element: 'view-screener',
	},
	{
		icon: discussionIcon,
		title: 'Check Trending Stocks on the Discovery Feed',
		text: 'Explore other investors’ trades and ideas to get new investment opportunities',
		linkTitle: 'Open Discovery Feed',
		linkTo: '/discover',
		element: 'view-discover',
	},
	{
		icon: teslaIcon,
		title: 'Deep Dive into a Stock',
		text: 'Check out the stock’s quote page to get all the information you need to make an informed decision',
		linkTitle: 'Open TSLA Quote Page',
		linkTo: '/quote/TSLA',
		element: 'view-quote',
	},
];

export default function WatchlistOnboardingModal(props) {
	const { handleClose } = props;
	return (
		<ul>
			{items.map(({ icon, title, text, linkTitle, linkTo, element }) => (
				<li key={title}>
					<div className="d-flex align-items-center pb-4">
						<img
							src={icon}
							alt="trade-icon"
							className="mb-2"
							height={38}
							width={38}
						/>
						<div className="px-3">
							<h5 className="mb-0 text-black">{title}</h5>
							<p
								className="mb-0 py-1 text-black"
								style={{ lineHeight: '20px' }}
							>
								{text}
							</p>
							{linkTo && linkTitle && element && (
								<Link
									to={linkTo}
									onClick={() => {
										handleClose();
										sendClickEvent({
											element,
											eventLocation: 'onboarding-checklist-follows',
										});
									}}
								>
									{linkTitle}
								</Link>
							)}
						</div>
					</div>
				</li>
			))}
		</ul>
	);
}
