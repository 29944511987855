import React, { useRef } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useScreen } from '../../context/UseScreenContext';

const OverlayTriggerComponent = (props) => {
	const { isComputer } = useScreen();
	const ref = useRef(null);
	const { name, info, placement, children } = props;
	return info ? (
		<OverlayTrigger
			placement={placement}
			// hides the tooltip when clicking elsewhere
			rootClose
			rootCloseEvent={isComputer ? 'mousedown' : 'click'}
			onHide={() => ref.current.hide()}
			// hover - shows/hides tooltip on computer, focus - is needed for accessibility
			// click trigger for mobile devices - on click opens tooltip, on click again closes, without focus doesn't work
			trigger={isComputer ? ['hover', 'focus'] : ['click', 'focus']}
			overlay={
				<Tooltip id="info-tooltip" name={name}>
					{info}
				</Tooltip>
			}
		>
			{children}
		</OverlayTrigger>
	) : (
		children
	);
};

export default OverlayTriggerComponent;
