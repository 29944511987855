import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TransactionOnboardingModal from './TransactionOnboardingModal';
import FollowsOnboardingModal from './FollowsOnboardingModal';
import WatchlistOnboardingModal from './WatchlistOnboardingModal';
import InfoModal from '../../../common/modals/InfoModal';

const modals = {
	transaction: {
		title: 'How to Make a Transaction',
		Content: TransactionOnboardingModal,
	},
	follows: {
		title: 'How to Find Investors to Follow',
		Content: FollowsOnboardingModal,
	},
	watchlist: {
		title: 'How to Find Companies to Watch',
		Content: WatchlistOnboardingModal,
	},
};

export default function BaseOnboardingModal(props) {
	const { onboardingModalType, setOnboardingModalType, setIsOpen } = props;
	const [isShow, setIsShow] = useState(false);
	const currentModal = useMemo(
		() => modals[onboardingModalType],
		[onboardingModalType]
	);
	const { title, Content } = useMemo(() => currentModal || {}, [currentModal]);
	const handleClose = useCallback(() => {
		setIsShow(false);
		if (typeof setIsOpen === 'function') {
			setIsOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setIsShow(Boolean(currentModal));
	}, [currentModal]);
	useEffect(() => {
		if (!isShow) {
			setTimeout(() => {
				setOnboardingModalType('');
			}, 200);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShow]);
	return (
		<InfoModal
			closeMethod="XButton"
			title={
				<div className="full-width">
					<span className="h2-font-size text-center">{title}</span>
				</div>
			}
			isShow={isShow}
			onChange={setIsShow}
			dialogClassName="modal-600px"
			contentClassName="px-lg-4 d-flex flex-column"
		>
			{Content ? <Content handleClose={handleClose} /> : null}
		</InfoModal>
	);
}
