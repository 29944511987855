import React, { useMemo } from 'react';
import OverlayTriggerComponent from './OverlayTriggerComponent';

const rankDescriptions = {
	rookie: (
		<>
			Members are ranked as <i>Rookie</i> upon new registration. To advance to
			the next level, they must gain at least 3 followers.
		</>
	),
	gsb: (
		<>
			Members are ranked <i>All-Star</i> <i>Master</i>, <i>Expert</i> or{' '}
			<i>Junior</i> based on their number of followers.
		</>
	),
	alpha: (
		<>
			Members get <i>Alpha</i> rank when beating the market in the last 1M/3M/6M
		</>
	),
};

const validRanks = ['Rookie', 'Junior', 'Expert', 'Master', 'All-Star'];

const rankClasses = {
	Rookie: 'rookie',
	Junior: 'bronze',
	Expert: 'silver',
	Master: 'gold',
	'All-Star': 'platinum',
};

export default function LinkedPortfolioTag(props) {
	const {
		rank,
		performance,
		isLarge,
		helpIcon,
		showInfo = true,
		style = {},
	} = props;
	const tagClass = useMemo(() => rank && rankClasses[rank], [rank]);
	const rankDescription = useMemo(
		() =>
			rankDescriptions[
				{
					'All-Star': 'gsb',
					Master: 'gsb',
					Expert: 'gsb',
					Junior: 'gsb',
					Rookie: 'rookie',
				}[rank]
			],
		[rank]
	);

	const rankRender = useMemo(
		() => (
			<div
				style={style}
				className={`user-label text-nowrap d-flex justify-content-center align-items-center${
					isLarge ? ' large' : ''
				} ${tagClass}`}
			>
				{rank}
			</div>
		),
		[style, isLarge, tagClass, rank]
	);

	if (!rank || !validRanks.includes(rank)) {
		return null;
	}

	if (!showInfo) {
		return rankRender;
	}

	return (
		<>
			{rank ? (
				<OverlayTriggerComponent
					info={rankDescription}
					placement={helpIcon ? 'right' : 'top'}
				>
					<div className="d-flex justify-content-center align-items-center">
						{rankRender}
						{helpIcon && (
							<i
								className="la la-question-circle text-primary"
								style={{ fontSize: '16px' }}
							/>
						)}
					</div>
				</OverlayTriggerComponent>
			) : null}
			{performance ? (
				<OverlayTriggerComponent info={rankDescriptions.alpha} placement="top">
					<div
						className={`ml-1 position-relative d-flex align-items-center user-performance ${
							isLarge ? 'large' : ''
						}`}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="5 -1 50 50"
							width={isLarge ? '16px' : '12px'}
							height={isLarge ? '16px' : '12px'}
						>
							<path d="M 42.421875 30.308594 C 42.230469 30.109375 41.972656 30 41.699219 30 L 40.199219 30 C 39.660156 30 39.21875 30.425781 39.199219 30.964844 C 39.09375 33.960938 38.3125 34.199219 37.300781 34.199219 C 36.222656 34.199219 35.183594 32.320313 34.433594 29.027344 C 34.734375 27.957031 35.191406 26.21875 35.777344 23.71875 L 39.277344 8.21875 C 39.34375 7.925781 39.269531 7.613281 39.082031 7.375 C 38.894531 7.136719 38.605469 7 38.300781 7 L 32.601563 7 C 32.132813 7 31.726563 7.324219 31.621094 7.785156 L 30.28125 13.859375 C 29.417969 11.773438 28.425781 10.066406 27.351563 8.839844 C 25.75 7.007813 23.457031 6 20.898438 6 C 17.171875 6 10.371094 8.527344 8.519531 18.003906 C 8.171875 19.753906 8 21.519531 8 23.398438 C 8 40.507813 19.488281 41 20.800781 41 C 25.429688 41 28.867188 39.210938 31.46875 35.410156 C 32.703125 39.078125 34.527344 41 36.800781 41 C 39.058594 41 40.234375 39.457031 41.171875 37.789063 C 42.101563 36.140625 42.585938 33.996094 42.699219 31.039063 C 42.710938 30.765625 42.609375 30.503906 42.421875 30.308594 Z M 27.71875 25.511719 C 27.171875 28.351563 26.414063 30.511719 25.890625 31.785156 C 24.171875 35.5625 22.390625 36 21.199219 36 C 19.914063 36 16 36 16 24 C 16 15.375 17.816406 11 21.398438 11 C 22.304688 11 24.777344 12.226563 28.257813 23.515625 Z" />
						</svg>
						{performance}
					</div>
				</OverlayTriggerComponent>
			) : null}
		</>
	);
}
