import React from 'react';
import { Link } from 'react-router-dom';
import discussionIcon from '../icons/discussion-icon.png';
import leaderboardIcon from '../icons/leaderboard-icon.png';
import appleIcon from '../icons/apple-icon.png';
import { sendClickEvent } from '../../../../utils/eventsService';

const items = [
	{
		icon: leaderboardIcon,
		title: 'Check the Leaderboard',
		text: 'Follow community leaders to unlock great investment ideas and engage in conversation with top investors',
		linkTitle: 'Open the Leaderboard',
		linkTo: '/leaderboard',
		element: 'view-leaderboard',
	},
	{
		icon: discussionIcon,
		title: 'Explore the Discovery Feed',
		text: 'Scroll through the discovery feed to find like-minded and interesting investors',
		linkTitle: 'Open Discovery Feed',
		linkTo: '/discover',
		element: 'view-discover',
	},
	{
		icon: appleIcon,
		title: 'Find Like-Minded Investors',
		text: 'Follow investors who share your interests, investment ideas and holdings to get their insights and ideas',
		linkTitle: 'Open AAPL Discussions',
		linkTo: '/quote/AAPL/discussions',
		element: 'view-discussions',
	},
];

export default function FollowsOnboardingModal(props) {
	const { handleClose } = props;
	return (
		<ul>
			{items.map(({ icon, title, text, linkTitle, linkTo, element }) => (
				<li key={title}>
					<div className="d-flex align-items-center pb-4">
						<img
							src={icon}
							alt="trade-icon"
							className="mb-2"
							height={38}
							width={38}
						/>
						<div className="px-3">
							<h5 className="mb-0 text-black">{title}</h5>
							<p
								className="mb-0 py-1 text-black"
								style={{ lineHeight: '20px' }}
							>
								{text}
							</p>
							<Link
								to={linkTo}
								onClick={() => {
									handleClose();
									sendClickEvent({
										element,
										eventLocation: 'onboarding-checklist-follows',
									});
								}}
							>
								{linkTitle}
							</Link>
						</div>
					</div>
				</li>
			))}
		</ul>
	);
}
