import React from 'react';
import { Link } from 'react-router-dom';
import tradeIcon from '../icons/trade-icon.png';
import transactionButtonExample from '../../../../images/transaction-button-example.png';
import transactionButtonExampleMobile from '../../../../images/transaction-button-example-mobile.png';
import { useAuth } from '../../../../context/Auth';
import { sendClickEvent } from '../../../../utils/eventsService';
import { useScreen } from '../../../../context/UseScreenContext';

export default function TransactionOnboardingModal(props) {
	const { handleClose } = props;
	const { authTokens } = useAuth();
	const { isComputer } = useScreen();
	return (
		<>
			<div className="d-flex align-items-center pb-3">
				<img src={tradeIcon} alt="trade-icon" height={38} width={38} />
				<p className="px-3 mb-0 text-black">
					To make a transaction, find the “Buy/Sell” button on your{' '}
					<Link
						to={`/u/${authTokens.user_name}`}
						onClick={() => {
							handleClose();
							sendClickEvent({
								element: 'view-profile',
								eventLocation: 'onboarding-checklist-transaction',
							});
						}}
					>
						portfolio page.
					</Link>
				</p>
			</div>
			<img
				src={
					isComputer ? transactionButtonExample : transactionButtonExampleMobile
				}
				alt=""
				className="pb-2 mx-auto"
				height={250}
				width={isComputer ? 502 : 150}
			/>
		</>
	);
}
