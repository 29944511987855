import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
	dsn: 'https://16dfd2b08c854b978fb1fbb7b707eae3@o1329750.ingest.sentry.io/6592024',
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
	],
	initialScope: (scope) => {
		scope.setLevel('fatal');
		return scope;
	},
	enabled: process.env.NODE_ENV !== 'development',
	release: `Jika@${process.env.REACT_APP_VERSION}`,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.2,
});

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
