import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OnboardingChecklistStep from './OnboardingChecklistStep';
import { useAuth } from '../../../context/Auth';
import BaseOnboardingModal from './modals/BaseOnboardingModal';
import { useScreen } from '../../../context/UseScreenContext';
import { sendEvent } from '../../../utils/eventsService';
import { rawChecklistSteps } from '../../../assets/checklist';

export default function OnboardingChecklist(props) {
	const { isComputer } = useScreen();
	const { authModalType, authTokens } = useAuth();
	const {
		isTourOpen,
		isOpen,
		setIsOpen,
		isVerified,
		updateChecklistVerifieds,
		isDone,
		isOff,
	} = props;
	const [isVerifiedState, setIsVerifiedState] = useState(isVerified);
	const [newVerified, setNewVerified] = useState('');
	const checklistSteps = useMemo(
		() =>
			rawChecklistSteps.filter((rawChecklistStep) => {
				const { verifiedRequired } = rawChecklistStep;
				if (!verifiedRequired) {
					return true;
				}
				if (verifiedRequired.includes('__')) {
					const [operator, verifiedRequiredName] = verifiedRequired.split('__');
					if (operator === '$ne') {
						return !isVerified.includes(verifiedRequiredName);
					}
				}
				return isVerified.includes(verifiedRequired);
			}),
		[isVerified]
	);
	const progressBarWidth = useMemo(
		() => (isComputer ? 260 : 195),
		[isComputer]
	);
	const [onboardingModalType, setOnboardingModalType] = useState('');
	const [openStep, setOpenStep] = useState('');
	const handleOpenChange = useCallback(() => {
		setIsOpen((currentState) => {
			sendEvent({
				gaName: `${currentState ? 'close' : 'open'}-onboarding-checklist`,
			});
			return !currentState;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [percentagesProgress, percentagesProgressWidth] = useMemo(
		() =>
			checklistSteps.reduce(
				(acc, { verified }) => {
					if (verified === true || isVerified.includes(verified)) {
						return [
							acc[0] + 100 / checklistSteps.length,
							acc[1] + progressBarWidth / checklistSteps.length,
						];
					}
					return acc;
				},
				[0, 0]
			),
		[isVerified, checklistSteps, progressBarWidth]
	);
	const checklistItemsRender = useMemo(
		() =>
			checklistSteps.map(({ title, text, verified, buttonTitle }) => {
				const tempIsVerified =
					verified === true || isVerified.includes(verified);
				const isLocked =
					![true, 'portfolio'].includes(verified) &&
					!isVerified.includes('portfolio');
				const isNewVerified = verified === newVerified;
				const handleSetOpenStep = () => {
					setNewVerified('');
					if (openStep === verified) {
						setOpenStep(null);
					} else if (text) {
						setOpenStep(verified);
						sendEvent({
							gaName: 'expand-onboarding-task',
							gaParams: { task_name: verified },
						});
					}
				};
				const handleSetOnboardingModalType = () => {
					setOnboardingModalType(verified);
					sendEvent({
						gaName: 'start-onboarding-task',
						gaParams: { task_name: verified },
						event_location: 'onboarding-task-button',
					});
				};
				return (
					<OnboardingChecklistStep
						key={title}
						title={title}
						text={text}
						isVerified={tempIsVerified}
						isOpen={!tempIsVerified && openStep === verified}
						isLocked={isLocked}
						setOpenStep={handleSetOpenStep}
						buttonTitle={buttonTitle}
						setOnBoardingModalType={handleSetOnboardingModalType}
						isNewVerified={isNewVerified}
						updateChecklistVerifieds={updateChecklistVerifieds}
					/>
				);
			}),
		[
			checklistSteps,
			isVerified,
			newVerified,
			openStep,
			updateChecklistVerifieds,
		]
	);
	useEffect(() => {
		if (
			isVerified.some((isVerifiedItem) => {
				if (!isVerifiedState.includes(isVerifiedItem)) {
					setIsOpen(true);
					setNewVerified(isVerifiedItem);
					sendEvent({
						gaName: 'complete-onboarding-task',
						gaParams: { task_name: isVerifiedItem },
					});
					return true;
				}
				return false;
			})
		) {
			setIsVerifiedState(isVerified);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVerified]);
	useEffect(() => {
		const { is_verified: tempIsVerified = [] } = authTokens;
		updateChecklistVerifieds(tempIsVerified, true);
		setIsVerifiedState(tempIsVerified);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return isOff ? null : (
		<>
			<div
				className={isOpen ? 'bg-white' : ''}
				style={{
					maxHeight: isTourOpen ? '0' : 'initial',
					visibility: isTourOpen ? 'hidden' : 'visible',
					zIndex: '99',
					borderRadius: '5px',
					overflow: 'hidden',
					width: `${progressBarWidth + 50}px`,
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
					position: 'fixed',
					bottom: '10px',
					transition: 'right 1s cubic-bezier(.96,.2,.56,.63)',
					...(['onboarding', 'success'].includes(authModalType) || isDone
						? {
								pointerEvents: 'none',
								right: `-${progressBarWidth + 150}px`,
						  }
						: {
								right: '10px',
						  }),
				}}
			>
				<div
					className="bg-primary text-white p-3"
					onClick={handleOpenChange}
					role="button"
					tabIndex={0}
				>
					<div className="d-flex align-items-center justify-content-between">
						<p className="m-0">Onboarding Checklist</p>
						<svg
							width="12"
							height="8"
							viewBox="0 0 12 8"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d={isOpen ? 'M1 1.5L6 6.5L11 1.5' : 'M11 6.5L6 1.5L1 6.5'}
								stroke="white"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					{!isOpen ? (
						<ul className="mt-2 d-flex">
							{checklistSteps.map(({ verified }) => (
								<li
									key={verified}
									className="mx-1"
									style={{
										width: '50px',
										height: '2px',
										backgroundColor:
											verified === true || isVerified.includes(verified)
												? '#FFF'
												: '#9D9D9D',
									}}
								/>
							))}
						</ul>
					) : null}
				</div>
				<div className={`onboarding-checklist${isOpen ? ' open' : ''}`}>
					<div className="px-2 pt-2 d-flex align-items-center">
						<div className="d-flex">
							<div
								className="bg-primary progress-bar"
								style={{
									width: `${percentagesProgressWidth}px`,
								}}
							/>
							<div
								className="progress-bar-bg"
								style={{
									width: `${progressBarWidth - percentagesProgressWidth}px`,
								}}
							/>
						</div>
						<p className="ml-1 mb-0 text-primary">
							<small>{Math.round(percentagesProgress)}%</small>
						</p>
					</div>
					<ul>{checklistItemsRender}</ul>
				</div>
			</div>
			<BaseOnboardingModal
				onboardingModalType={onboardingModalType}
				setOnboardingModalType={setOnboardingModalType}
				setIsOpen={setIsOpen}
			/>
		</>
	);
}
