import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import axios from 'axios';
import { useAuth } from './Auth';
import isEmbedRoute from '../utils/isEmbedRoute';

const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = (props) => {
	const { children } = props;
	const { authTokens, getAuthHeader } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [isUnread, setIsUnread] = useState(false);
	const [notifTrig, setNotifTrig] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [lastNotificationId, setLastNotificationId] = useState(0);
	const [unseenCount, setUnseenCount] = useState(0);
	const [unreadCount, setUnreadCount] = useState(0);
	const setNotificationsSeen = useCallback(() => {
		if (unreadCount > 0) {
			axios
				.post('/api/stream/notifications/user/seen', null, {
					headers: getAuthHeader(),
				})
				.then((res) => {
					setUnseenCount(res?.data?.user?.unseen);
				});
		}
	}, [unreadCount, getAuthHeader]);
	const setNotificationRead = useCallback(
		(notificationId, notificationIndex, isRead = true) => {
			axios
				.post(
					'/api/stream/notifications/user/read',
					{ notificationId, isRead },
					{
						headers: getAuthHeader(),
					}
				)
				.then((res) => {
					setUnreadCount(res?.data?.user?.unread);
					setNotifications((currentState) => {
						if (notificationId === 'all') {
							return [];
						}
						if (isUnread) {
							return currentState.filter(
								(notification, index) => index !== notificationIndex
							);
						}
						return currentState.map((notification, index) =>
							index === notificationIndex
								? { ...notification, is_read: true }
								: notification
						);
					});
				});
		},
		[isUnread, getAuthHeader]
	);
	const value = useMemo(
		() => ({
			notifications,
			unreadCount,
			setUnreadCount,
			unseenCount,
			setUnseenCount,
			setNotificationRead,
			setNotificationsSeen,
			setLastNotificationId,
			setNotifTrig,
			setNotifications,
			isUnread,
			setIsUnread,
			isLoading,
		}),
		[
			notifications,
			unreadCount,
			unseenCount,
			setNotificationRead,
			setNotificationsSeen,
			isUnread,
			isLoading,
		]
	);
	// const isInitialNotifs = useRef(true);
	const getNotifications = useCallback(
		async (lastNotificationIdArg = lastNotificationId) =>
			axios.get(
				`/api/stream/notifications/user?offset=${lastNotificationIdArg}${
					isUnread ? '&unread=true' : ''
				}`,
				{
					headers: getAuthHeader(),
				}
			),
		[getAuthHeader, lastNotificationId, isUnread]
	);
	useEffect(() => {
		if (lastNotificationId) {
			getNotifications(0).then((res) => {
				setLastNotificationId(res?.data?.last_notification);
				setNotifications(res?.data?.user?.results);
				setUnreadCount(res?.data?.user?.unread);
				setUnseenCount(res?.data?.user?.unseen);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUnread]);
	useEffect(() => {
		if (!isEmbedRoute && authTokens) {
			if (lastNotificationId !== 'none') {
				getNotifications()
					.then((res) => {
						setIsLoading(true);
						setLastNotificationId(res?.data?.last_notification);
						setNotifications((currentState) => {
							if (lastNotificationId === 0 && currentState.length > 0) {
								return res?.data?.user?.results;
							}
							const { results } = res?.data?.user || {};
							if (results?.length > 0) {
								return currentState.length > 0 &&
									currentState[0].id === results[0].id
									? results
									: [...currentState, ...(results || [])];
							}
							return currentState;
						});
						setUnreadCount(res?.data?.user?.unread);
						setUnseenCount(res?.data?.user?.unseen);
					})
					.finally(() => setIsLoading(false));
			}
		} else {
			setLastNotificationId(0);
			setNotifications([]);
			setUnreadCount(0);
			setUnseenCount(0);
		}
		// eslint-disable-next-line
	}, [authTokens, notifTrig]);
	return (
		<NotificationsContext.Provider value={value}>
			{children}
		</NotificationsContext.Provider>
	);
};
