import { useState } from 'react';

const useDebounce = (func, timeout = 300) => {
	const [timer, setTimer] = useState();
	return (...args) => {
		clearTimeout(timer);
		setTimer(
			setTimeout(() => {
				func.apply(this, args);
			}, timeout)
		);
	};
};

export default useDebounce;
