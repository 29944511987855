export const FIRST_MODAL_VIEW = 'firstModalView';

export const addFirstModalView = () => {
	if (!localStorage.getItem(FIRST_MODAL_VIEW)) {
		localStorage.setItem(
			FIRST_MODAL_VIEW,
			JSON.stringify({
				home: false,
				trafficTool: false,
				analyze: false,
				simulator: false,
				peers: false,
				screener: false,
			})
		);
	}
};

export const checkFirstModalView = (target) => {
	if (!localStorage.getItem(FIRST_MODAL_VIEW)) {
		addFirstModalView();
	}
	const firstModalView = JSON.parse(localStorage.getItem(FIRST_MODAL_VIEW));
	if (firstModalView[target] === false) return true;
	return false;
};

export const setFirstModalView = (target) => {
	const firstModalView = JSON.parse(localStorage.getItem(FIRST_MODAL_VIEW));
	firstModalView[target] = true;
	localStorage.setItem(FIRST_MODAL_VIEW, JSON.stringify(firstModalView));
};
