import React from 'react';
import { Modal } from 'react-bootstrap';
import BaseModal from './BaseModal';

/**
 * @function FormModal
 * @param {{
 * title?: string | Node;
 * footer?: string | Node;
 * isShow: boolean;
 * onChange?: Function;
 * onceView?: Function;
 * trackId?: string;
 * children?: Node;
 * }} props
 * @returns
 */

const FormModal = (props) => {
	const {
		title,
		footer,
		isShow,
		onChange,
		onceView,
		trackId,
		dialogClassName,
		contentClassName,
		bodyStyle = { width: '100%', maxWidth: '500px' },
		noClose,
		children,
	} = props;
	return (
		<BaseModal
			isShow={isShow}
			onChange={onChange}
			onceView={onceView}
			trackId={trackId}
			dialogClassName={dialogClassName}
			contentClassName={contentClassName}
		>
			<Modal
				backdrop={noClose ? 'static' : undefined}
				onEscapeKeyDown={(event) => {
					if (noClose) {
						event.preventDefault();
						if (typeof noClose === 'function') {
							noClose(event);
						}
					}
				}}
			>
				<Modal.Header className="border-bottom-0 p-1" closeButton={!noClose}>
					{title ? <Modal.Title>{title}</Modal.Title> : null}
				</Modal.Header>

				<Modal.Body className="row justify-content-center">
					<div
						className="d-flex flex-column justify-content-center align-items-center"
						style={bodyStyle}
					>
						{children}
					</div>
				</Modal.Body>

				{footer ? <Modal.Footer>{footer}</Modal.Footer> : null}
			</Modal>
		</BaseModal>
	);
};

export default FormModal;
