import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import AsyncSelect from 'react-select/async';

/**
 *
 * @param {{
 * hideSearchIcon;
 * isFocused;
 * returnDetailedObject;
 * className;
 * searchboxRef;
 * onChange;
 * onInputChange;
 * isMulti;
 * placeholder;
 * styles;
 * value;
 * inputValue;
 * autoFocus;
 * valueKey;
 * searchBoxName;
 * searchBoxOptions;
 * loadOptions;
 * menuIsOpen;
 * }} props
 * @returns
 */

export default function MainSearchboxBase({
	isDisabled,
	hideSearchIcon,
	returnDetailedObject,
	searchboxRef,
	searchBoxName,
	searchBoxOptions,
	onChange = () => {},
	onInputChange,
	className,
	placeholder,
	autoFocus,
	isFocused,
	isMulti = false,
	styles = {},
	value = '',
	inputValue = '',
	valueKey,
	loadOptions,
	menuIsOpen,
	isSearchable = true,
	defaultValue = '',
}) {
	const selectRef = useRef(null);
	const isInitialMount = useRef(true);
	useEffect(() => {
		if ((isInitialMount.current && isFocused) || !isInitialMount.current) {
			isInitialMount.current = false;
			selectRef.current.focus();
		}
	}, [isFocused]);

	const searchIcon = useCallback(
		() => (
			<div
				style={{
					marginLeft: '1em',
					marginRight: '0',
					color: 'hsl(0, 0%, 50%)',
				}}
			>
				<i className="la la-search" />
			</div>
		),
		[]
	);

	const searchBoxOptionsToUse = useMemo(
		() =>
			searchBoxOptions && valueKey && !returnDetailedObject
				? searchBoxOptions.map((sublist) => {
						const tempSublist = { ...sublist };
						tempSublist.options = tempSublist.options.map((item) => {
							const tempCompany = { ...item };
							if (Object.keys(tempCompany?.value).includes(valueKey)) {
								tempCompany.value = tempCompany.value[valueKey];
							}
							return tempCompany;
						});
						return tempSublist;
				  })
				: searchBoxOptions,
		[searchBoxOptions, valueKey, returnDetailedObject]
	);

	const newStyles = useMemo(() => {
		const tempStyles = {
			indicatorSeparator: () => null,
			menu: (base) => ({
				...base,
				zIndex: 10000,
			}),
			...styles,
		};
		return tempStyles;
	}, [styles]);
	return (
		<div role="button" tabIndex={0} className={className} ref={searchboxRef}>
			<AsyncSelect
				onChange={onChange}
				onInputChange={onInputChange}
				components={{
					DropdownIndicator: hideSearchIcon || value ? null : searchIcon,
				}}
				placeholder={placeholder}
				cacheOptions
				loadOptions={loadOptions}
				className="basic-single"
				classNamePrefix="select"
				isSearchable={isSearchable}
				menuIsOpen={menuIsOpen}
				isClearable
				name={searchBoxName}
				autoFocus={autoFocus}
				ref={selectRef}
				openMenuOnFocus
				defaultOptions={searchBoxOptionsToUse}
				isMulti={isMulti}
				defaultValue={defaultValue || value}
				defaultInputValue={inputValue}
				key={JSON.stringify(value?.value || value)}
				styles={newStyles}
				isDisabled={isDisabled}
			/>
		</div>
	);
}
