import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../../../../context/Auth';
import MainSearchbox from '../../MainSearchbox';
import WideRowsTable from '../../tables/WideRowsTable';
import { sendEvent } from '../../../../utils/eventsService';
import InfoTooltip from '../../InfoTooltip';
import ConnectPortfolioButton from '../../ConnectPortfolioButton';
import plaidLogo from '../../../../images/plaid-logo-full-dark.svg';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import CalculatePortfolio from './CalculatePortfolio';

const tableHeaders = [
	{ label: 'Company' },
	<TableCell style={{ fontWeight: '600', fontFamily: 'Nunito' }}>
		<InfoTooltip
			info="Manual portfolio holdings are given last known market prices in order to show verified member performance. If you would like to show a longer portfolio history, consider connecting your portfolio with Plaid."
			title="Price"
			showIcon
		/>
	</TableCell>,
	{ label: 'Quantity' },
	{ label: '% of Portfolio' },
	{ label: '' },
];

const initialManualHoldings = [
	{ key: Date.now(), symbol: '', quantity: 0, weight: 0, price: 0 },
	{
		key: Date.now() + 1,
		symbol: 'cash',
		quantity: 0,
		weight: 0,
		price: 1,
		converted_price: 1,
	},
];

const titles = {
	selection: 'Add your portfolio',
	setManually: 'Add your portfolio manually',
	cashDeposit: 'Add your first cash deposit',
};

export default function LinkBrokerageStage(props) {
	const { nextStage, isOnboarding, stage: stageProp } = props;
	const {
		getAuthHeader,
		setAuthTokens,
		setLoadingPortfolio,
		loadingPortfolio,
	} = useAuth();
	const location = useLocation();
	const { updateChecklistVerifieds } = useOnboarding();
	const [manualHoldings, setManualHoldings] = useState(initialManualHoldings);
	const [stage, setStage] = useState(
		stageProp ||
			(location.pathname.includes('landing/virtual-trading')
				? 'cashDeposit'
				: 'selection')
	);
	const [portfolioError, setPortfolioError] = useState('');
	const addAManualHolding = useCallback(() => {
		setManualHoldings((currentState) => [
			...currentState.slice(0, currentState.length - 1),
			{
				key: Date.now(),
				symbol: '',
				quantity: 0,
				weight: 0,
				price: 0,
				converted_price: 0,
			},
			currentState[currentState.length - 1],
		]);
	}, []);

	const backToSelection = useCallback(() => {
		setStage('selection');
		setManualHoldings(() => initialManualHoldings);
	}, []);

	const calculateHoldingsWeight = useCallback((currentState, index, value) => {
		let totalPortfolioValue = 0;
		currentState.forEach((holding, i) => {
			if (i === index) {
				totalPortfolioValue += value * holding.converted_price;
			} else {
				totalPortfolioValue += holding.quantity * holding.converted_price;
			}
		});
		return currentState.map((holding, i) => {
			let newWeight;
			const tempHolding = { ...holding };
			if (i === index) {
				tempHolding.quantity = value;
				if (value && value !== 0) {
					sendEvent({
						gaName: 'change_quantity_of_symbol_in_portfolio',
						gaParams: { symbols: [tempHolding.symbol] },
					});
				}
				newWeight = (
					((value * tempHolding.converted_price) / totalPortfolioValue) *
					100
				).toFixed(2);
			} else {
				newWeight = (
					((tempHolding.quantity * tempHolding.converted_price) /
						totalPortfolioValue) *
					100
				).toFixed(2);
			}
			if (Number.isNaN(parseFloat(newWeight))) {
				newWeight = 0;
			}
			tempHolding.weight = newWeight;
			return tempHolding;
		});
	}, []);

	const handleSymbolSelect = useCallback(
		(symbol, index) => {
			sendEvent({
				gaName: 'select_symbol_to_portfolio',
				gaParams: { symbols: [symbol] },
			});
			axios
				.get(`/api/get_stock_price/${symbol}`, { headers: getAuthHeader() })
				.then((res) => {
					setManualHoldings((currentState) =>
						calculateHoldingsWeight(
							currentState.map((holding, i) => {
								if (i === index) {
									return {
										...holding,
										symbol,
										price: res.data.results.price,
										converted_price: res.data.results.converted_price,
									};
								}

								return holding;
							}),
							index,
							currentState[index].quantity
						)
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		[getAuthHeader, calculateHoldingsWeight]
	);

	const handleCashDepositSend = useCallback(() => {
		setLoadingPortfolio(true);
		axios
			.post(
				'/api/portfolio/manual',
				{
					holdings: [
						{
							symbol: 'cash',
							quantity: 10000,
						},
					],
				},
				{
					headers: getAuthHeader(),
				}
			)
			.then((res) => {
				const { is_verified: isVerified } = res.data.results;
				setAuthTokens((currentState) => ({
					...currentState,
					is_verified: isVerified,
				}));
				updateChecklistVerifieds(isVerified);
				sendEvent({
					gaName: 'connect_portfolio',
					gaParams: { method: 'cash', failed: false },
				});
				setTimeout(() => {
					nextStage();
				}, 3000);
			})
			.catch((error) => {
				sendEvent({
					gaName: 'connect_portfolio',
					gaParams: { method: 'cash', failed: true },
				});
				if (error?.response?.data?.message) {
					setPortfolioError(error?.response?.data?.message);
					setLoadingPortfolio(false);
				} else {
					nextStage();
				}
			});
	}, [
		getAuthHeader,
		nextStage,
		setAuthTokens,
		updateChecklistVerifieds,
		setLoadingPortfolio,
	]);

	const handleQuantityChange = useCallback(
		(event, index) => {
			const { value } = event.target;
			const valueNumber = Number(value);
			if (!Number.isNaN(valueNumber) && Number.isFinite(valueNumber)) {
				setManualHoldings((currentState) =>
					calculateHoldingsWeight(currentState, index, valueNumber)
				);
			}
		},
		[calculateHoldingsWeight]
	);

	const isDisabled = useMemo(
		() =>
			manualHoldings.some(
				(holding) =>
					holding.symbol !== 'cash' &&
					(holding.symbol === '' ||
						Number(holding.quantity) <= 0 ||
						Number.isNaN(Number(holding.quantity)))
			),
		[manualHoldings]
	);

	const removeAManualHolding = (index) => {
		setManualHoldings((currentState) =>
			calculateHoldingsWeight([
				...currentState.slice(0, index),
				...currentState.slice(index + 1),
			])
		);
	};

	const createManualPortfolio = useCallback(() => {
		setLoadingPortfolio(true);
		axios
			.post(
				'/api/portfolio/manual',
				{
					holdings: manualHoldings.map(({ symbol, quantity }) => ({
						symbol,
						quantity,
					})),
				},
				{
					headers: getAuthHeader(),
				}
			)
			.then((res) => {
				const { is_verified: isVerified } = res.data.results;
				setAuthTokens((currentState) => ({
					...currentState,
					is_verified: isVerified,
				}));
				updateChecklistVerifieds(isVerified);
				sendEvent({
					gaName: 'connect_portfolio',
					gaParams: { method: 'manual', failed: false },
				});
				setTimeout(() => {
					nextStage();
				}, 3000);
			})
			.catch((error) => {
				sendEvent({
					gaName: 'connect_portfolio',
					gaParams: { method: 'manual', failed: true },
				});
				if (error?.response?.data?.message) {
					setPortfolioError(error?.response?.data?.message);
					setLoadingPortfolio(false);
				} else {
					nextStage();
				}
			});
	}, [
		nextStage,
		manualHoldings,
		getAuthHeader,
		setLoadingPortfolio,
		setAuthTokens,
		updateChecklistVerifieds,
	]);

	useEffect(() => {
		if (stage === 'cashDeposit') {
			handleCashDepositSend();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return portfolioError ? (
		<div className="text-center">
			<h2 className="text-danger">Something went wrong!</h2>
			<p>{portfolioError}</p>
			<Link to="/contact">Contact Us</Link>
		</div>
	) : (
		<div className="row my-lg-5">
			{!loadingPortfolio && <h2 className="col-12">{titles[stage]}</h2>}
			{stage === 'selection' && !loadingPortfolio && (
				<>
					<p className="col-12">
						Jika.io is a portfolio-based community of investors.
						<br />
						Either insert your portfolio manually or link a brokerage account.
					</p>
					<div className="col-12">
						<div
							role="button"
							tabIndex={1}
							className="card portfolio-card"
							onClick={() => setStage('setManually')}
						>
							<div className="card-body">
								<div className="d-flex align-items-center">
									<div className="media">
										<i className="fa fa-wrench" />
									</div>
									<div className="bullets">
										<h4>Insert your portfolio manually</h4>
										<ul className="content-bullets">
											<li>
												<i className="fa fa-circle" /> Your portfolio begins
												today, no history
											</li>
											<li>
												<i className="fa fa-circle" /> Report your transactions
												manually
											</li>
											<li>
												<i className="fa fa-circle" /> Cash is treated as an
												holding
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<ConnectPortfolioButton
							eventLocation="onboarding_temp"
							afterConnect={nextStage}
						>
							<div className="card portfolio-card cursor-pointer">
								<div className="card-body">
									<div className="d-flex align-items-center">
										<div className="media">
											<i className="fa fa-link" />
										</div>
										<div className="bullets">
											<h4>Link your portfolio</h4>
											<ul className="content-bullets">
												<li>
													<i className="fa fa-circle" /> Up to 2 years of prior
													portfolio history
												</li>
												<li>
													<i className="fa fa-circle" /> Portfolio updates
													automatically
												</li>
												<li>
													<i className="fa fa-circle" />
													<p className="mb-0">
														Your information is fully secured with
														<img
															className="mx-1 plaid-logo"
															style={{ height: '19px' }}
															src={plaidLogo}
															alt="Plaid"
														/>
													</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</ConnectPortfolioButton>
					</div>
					{isOnboarding &&
						!location.pathname.includes('landing/portfolio-tracker') && (
							<div className="col-12">
								<button
									type="button"
									className="btn btn-none btn-block text-dark"
									onClick={() => nextStage()}
								>
									Setup later
								</button>
							</div>
						)}
					<p className="col-12">
						<i className="la la-check mr-2" />
						We never show portfolio dollar amounts, only percentages.
					</p>
				</>
			)}
			{stage === 'setManually' && !loadingPortfolio && (
				<>
					<div className="col-12">
						{manualHoldings.length > 0 && (
							<WideRowsTable
								headers={tableHeaders}
								tableStyle={{ overflow: 'inherit' }}
							>
								{manualHoldings.map((item, index) => (
									<TableRow key={item.key}>
										<TableCell style={{ width: 150, maxWidth: 150 }}>
											{item.symbol !== 'cash' ? (
												<MainSearchbox
													placeholder="Symbol"
													hideSearchIcon
													onChange={(symbol) =>
														handleSymbolSelect(symbol, index)
													}
												/>
											) : (
												'Cash'
											)}
										</TableCell>
										<TableCell>{item.price}</TableCell>
										<TableCell>
											<input
												type="text"
												value={item.quantity}
												style={{ width: 50 }}
												onChange={(event) => handleQuantityChange(event, index)}
											/>
										</TableCell>
										<TableCell>{item.weight}%</TableCell>
										{manualHoldings.length > 1 && item.symbol !== 'cash' ? (
											<TableCell
												className="cursor-pointer"
												onClick={() => removeAManualHolding(index)}
												style={{ fontSize: '1.3em' }}
											>
												<i className="la la-minus-circle text-danger" />
											</TableCell>
										) : (
											<TableCell />
										)}
									</TableRow>
								))}
								<TableRow
									key="add_holding"
									className="cursor-pointer"
									onClick={addAManualHolding}
								>
									<TableCell />
									<TableCell />
									<TableCell />
									<TableCell />
									<TableCell style={{ fontSize: '1.3em' }}>
										<i className="la la-plus-circle text-success" />
									</TableCell>
								</TableRow>
							</WideRowsTable>
						)}
					</div>
					<div className="col-12 mt-2">
						<button
							type="button"
							className="btn btn-success btn-block"
							disabled={isDisabled || loadingPortfolio}
							onClick={createManualPortfolio}
						>
							Next
						</button>
					</div>
					<div className="col-12 mt-2">
						<button
							type="button"
							className="btn btn-none btn-block"
							onClick={backToSelection}
						>
							Back
						</button>
					</div>
				</>
			)}
			{loadingPortfolio && <CalculatePortfolio />}
		</div>
	);
}
