import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import lockedImg from '../../../images/locked.png';

export default function OnboardingChecklistStep(props) {
	const {
		title,
		text,
		isVerified,
		isOpen,
		isLocked,
		setOpenStep,
		buttonTitle,
		setOnBoardingModalType,
		isNewVerified,
	} = props;

	const buttonRender = useMemo(() => {
		if (!buttonTitle) {
			return null;
		}
		if (typeof buttonTitle === 'string') {
			return (
				<Button
					variant="primary"
					className="py-1 mb-2"
					onClick={setOnBoardingModalType}
				>
					<small>{buttonTitle}</small>
				</Button>
			);
		}
		return buttonTitle;
	}, [buttonTitle, setOnBoardingModalType]);
	const isButtonDisabled = useMemo(
		() => isLocked || !text || isVerified,
		[isLocked, text, isVerified]
	);
	return (
		<li className={`w-100 px-3 py-1 checklist-item${isOpen ? ' active' : ''}`}>
			<Button
				className="w-100 d-flex align-items-center justify-content-between p-0"
				style={{ minWidth: 'initial', fontWeight: 500 }}
				variant="none"
				onClick={setOpenStep}
				disabled={isButtonDisabled}
			>
				<div className="d-flex align-items-center">
					<div
						className="d-flex align-items-center justify-content-center pr-2"
						style={{ minHeight: '28px' }}
					>
						{isLocked ? (
							<div
								className="d-flex align-items-center justify-content-center"
								style={{
									height: '17px',
									width: '16px',
									borderRadius: '50%',
									backgroundColor: '#1652F0',
								}}
							>
								<img src={lockedImg} alt="lock" height="100%" />
							</div>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								className={`onboarding-checklist-mark ${
									isNewVerified ? ' animate' : ''
								}${isVerified ? ' verified' : ''}`}
							>
								<circle cx="8" cy="8" r="8" fill="#1652f0" />
								<path
									d="m 3.7 8 l 3 3 l 5.5 -5.5"
									stroke="white"
									strokeWidth={1.5}
									fill="none"
								/>
							</svg>
						)}
					</div>
					<p
						className={`mb-0 checklist-item-title${
							isVerified ? ' verified' : ''
						}${isLocked ? ' locked' : ''}`}
					>
						<small>{title}</small>
					</p>
				</div>
				{isButtonDisabled ? null : (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width={isOpen ? '12' : '7'}
						height={isOpen ? '9' : '12'}
						viewBox={isOpen ? '0 0 12 8' : '0 0 7 12'}
						fill="none"
					>
						<path
							d={isOpen ? 'M1 1.5L6 6.5L11 1.5' : 'M1 11L6 6L1 1'}
							stroke="#1652F0"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</Button>
			{!isLocked && isOpen ? (
				<div>
					<p className="text-black mt-2 mb-1 px-1">
						<small>{text}</small>
					</p>
					{buttonRender}
				</div>
			) : null}
		</li>
	);
}
