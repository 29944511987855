import axios from 'axios';
import React, {
	useMemo,
	useCallback,
	useState,
	useContext,
	createContext,
	useEffect,
} from 'react';
import { useAuth } from './Auth';

const CheckUpdateContext = createContext({
	mounted: {
		watchlist: false,
		marketMovers: false,
		notifications: false,
	},
	data: {},
});

const CheckUpdatesProvider = (props) => {
	const { children } = props;
	const { authTokens, getAuthHeader } = useAuth();
	const [mounted, setMounted] = useState({
		watchlist: false,
		marketMovers: [],
		notifications: false,
	});
	const [data, setData] = useState({});
	const handleUpdateState = useCallback((name, value) => {
		if (name === 'mounted') {
			if (typeof value === 'function') {
				setMounted(value);
			} else {
				setMounted((currentState) => ({
					...currentState,
					...value,
				}));
			}
		} else {
			setData(value);
		}
	}, []);
	const [intervalId, setIntervalId] = useState();
	const [timeoutId, setTimeoutId] = useState();
	useEffect(() => {
		if (intervalId) {
			clearInterval(intervalId);
		}
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		const currentRequest = () => {
			let requestUrl = '';
			const mountedKeys = Object.keys(mounted);
			mountedKeys.forEach((key) => {
				const mountedValue = mounted[key];
				if (mountedValue) {
					if (!requestUrl) {
						requestUrl = '/api/check/updates?';
					}
					if (Array.isArray(mountedValue)) {
						if (mountedValue.length > 0) {
							requestUrl += `${key}=`;
							mountedValue.forEach((mountedValueKey) => {
								requestUrl += `${mountedValueKey},`;
							});
							requestUrl = requestUrl.replace(/,$/, '&');
						}
					} else {
						requestUrl += `${key}=${mountedValue}&`;
					}
				}
			});
			if (requestUrl) {
				axios.get(requestUrl, { headers: getAuthHeader() }).then((res) => {
					setData(res.data.results);
				});
			}
		};
		setTimeoutId(setTimeout(currentRequest, 1000));
		setIntervalId(setInterval(currentRequest, 10000));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mounted, authTokens]);
	return (
		<CheckUpdateContext.Provider
			value={useMemo(
				() => ({
					mounted,
					data,
					handleUpdateState,
				}),
				[mounted, data, handleUpdateState]
			)}
		>
			{children}
		</CheckUpdateContext.Provider>
	);
};

const useCheckUpdates = () => useContext(CheckUpdateContext);

export { CheckUpdatesProvider, useCheckUpdates };
