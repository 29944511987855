import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useAuth } from './Auth';
import CalendlyButton from '../components/common/CalendlyButton';
import useViewEvent from '../hooks/useViewEvent';
import { sendClickEvent } from '../utils/eventsService';
import { useABTesting } from './ABTestingManager';

const interviewName = 'interview-second-day';
const abTestingVariantName = 'interview-floater';

const InterviewContext = createContext();

/**
 *
 * @returns {{showInterview: boolean}}
 */

const useInterviewContext = () => useContext(InterviewContext);

const InterviewProvider = (props) => {
	const { children } = props;

	const { authTokens, getAuthHeader } = useAuth();

	const { getVariant } = useABTesting();

	const interviewVariant = getVariant(abTestingVariantName, ['A', 'B', 'C']);

	const [isOff, setIsOff] = useState(false);
	const [showInterview, setShowInterview] = useState(false);

	const handleInterviewOff = useCallback(() => {
		setShowInterview(false);
		setTimeout(() => {
			setIsOff(true);
		}, 1000);
	}, []);

	const onEventScheduled = useCallback(
		() =>
			axios
				.post(
					`/api/interview/${interviewName}`,
					{ status: 'scheduled' },
					{ headers: getAuthHeader() }
				)
				.finally(() => {
					sendClickEvent({
						element: `scheduled-${interviewName}-floater`,
						variant: interviewVariant,
					});
					handleInterviewOff();
				}),
		[getAuthHeader, handleInterviewOff, interviewVariant]
	);

	const onRemindLater = useCallback(() => {
		sendClickEvent({
			element: `remind-later-${interviewName}-floater`,
			variant: interviewVariant,
		});
		setShowInterview(false);
	}, [interviewVariant]);

	const onDeniedInterview = useCallback(
		() =>
			axios
				.post(
					`/api/interview/${interviewName}`,
					{ status: 'denied' },
					{ headers: getAuthHeader() }
				)
				.finally(() => {
					sendClickEvent({
						element: `denied-${interviewName}-floater`,
						variant: interviewVariant,
					});
					handleInterviewOff();
				}),
		[getAuthHeader, handleInterviewOff, interviewVariant]
	);

	const value = useMemo(() => ({ showInterview }), [showInterview]);

	useEffect(() => {
		if (authTokens && interviewVariant !== 'loading') {
			setIsOff(false);
			axios
				.get(`/api/interview/${interviewName}`, {
					headers: getAuthHeader(),
				})
				.then((res) => {
					setTimeout(() => {
						setShowInterview(res.data.results);
					}, 100);
				});
		} else {
			setIsOff(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens, interviewVariant]);

	useViewEvent(
		authTokens && !isOff && interviewVariant !== 'loading',
		`view-${interviewName}-floater`,
		{
			variant: interviewVariant,
		}
	);

	return (
		<InterviewContext.Provider value={value}>
			{children}
			{authTokens && !isOff && interviewVariant ? (
				<div
					className="p-3 bg-primary text-white"
					style={{
						zIndex: '99',
						position: 'fixed',
						bottom: '10px',
						right: '10px',
						maxWidth: '394px',
						borderRadius: '5px',
						boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
						transition: 'right 1s cubic-bezier(.96,.2,.56,.63)',
						...(showInterview
							? {
									right: '10px',
							  }
							: {
									pointerEvents: 'none',
									right: '-150%',
							  }),
					}}
				>
					{interviewVariant === 'A' && (
						<>
							<div className="mb-2 d-flex align-items-center justify-content-between">
								<small style={{ fontSize: '12px' }} className="text-white mb-0">
									Help us build Jika to meet your needs 🛠️
								</small>
								<Button
									variant="none"
									className="p-0"
									style={{ minWidth: 'initial' }}
									onClick={onRemindLater}
								>
									<span className="fa fa-close text-white" />
								</Button>
							</div>
							<h3 style={{ fontSize: '18px' }} className="text-white mb-2">
								Get a $10 Amazon gift card
							</h3>
							<p style={{ fontSize: '14px' }} className="text-white mb-0">
								For a 20-minute interview with Jika's CEO
							</p>
						</>
					)}
					{interviewVariant === 'B' && (
						<>
							<div className="mb-2 d-flex align-items-center justify-content-between">
								<small style={{ fontSize: '12px' }} className="text-white mb-0">
									Help us build Jika to meet your needs 🛠️
								</small>
								<Button
									variant="none"
									className="p-0"
									style={{ minWidth: 'initial' }}
									onClick={onRemindLater}
								>
									<span className="fa fa-close text-white" />
								</Button>
							</div>
							<h3 style={{ fontSize: '18px' }} className="text-white mb-2">
								Get a $10 Amazon gift card
							</h3>
							<p style={{ fontSize: '14px' }} className="text-white mb-0">
								For a 20-minute interview
							</p>
						</>
					)}
					{interviewVariant === 'C' && (
						<>
							<div className="mb-3 d-flex align-items-center justify-content-between">
								<h4 className="text-white mb-0">
									Hey {authTokens.user_name} 👋
								</h4>
								<Button
									variant="none"
									className="p-0"
									style={{ minWidth: 'initial' }}
									onClick={onRemindLater}
								>
									<span className="fa fa-close text-white" />
								</Button>
							</div>
							<p className="mb-0">
								Jika will soon update to better fit your needs.
							</p>
							<p className="mb-0">
								Set up a <strong>20-minute</strong> meeting to shape our next
								update and get a $10 Amazon gift card.
							</p>
						</>
					)}
					<div className="mt-3 d-flex align-items-center">
						<CalendlyButton
							variant="white"
							btnClassName="text-primary mr-3"
							eventLocation="interview-floater"
							onEventScheduled={onEventScheduled}
							variantKey={interviewVariant}
						>
							Schedule
						</CalendlyButton>
						<Button
							variant="link"
							className="text-white"
							onClick={onDeniedInterview}
							style={{ fontSize: '14px' }}
						>
							Don't Show Again
						</Button>
					</div>
				</div>
			) : null}
		</InterviewContext.Provider>
	);
};

export { useInterviewContext, InterviewProvider };
