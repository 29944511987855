import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './Auth';
import OnboardingChecklist from '../components/dashboard/onboardingChecklist/OnboardingChecklist';
import { OnboardingContext } from '../hooks/useOnboarding';
import { rawChecklistSteps } from '../assets/checklist';
import { useEmbed } from './Embed';

const OnboardingProviderContent = (props) => {
	const { value: valueProp, children } = props;
	const { isEmbed } = useEmbed();
	const location = useLocation();
	const { authTokens } = useAuth();
	const [isChecklistOpen, setIsChecklistOpen] = useState(false);

	const landingPage = useMemo(() => {
		if (!location.pathname.includes('landing/')) {
			return '';
		}
		const pathNameArray = location.pathname.split('/');
		return pathNameArray[pathNameArray.length - 1];
		// eslint-disable-next-line
	}, []);

	const value = useMemo(
		() => ({
			...valueProp,

			isChecklistOpen,
			setIsChecklistOpen,
			landingPage,
		}),
		[valueProp, isChecklistOpen, landingPage]
	);
	return (
		<OnboardingContext.Provider value={value}>
			{children}
			{!isEmbed && !location.pathname.includes('/academy') && authTokens ? (
				<OnboardingChecklist
					isOpen={isChecklistOpen}
					setIsOpen={setIsChecklistOpen}
					isVerified={valueProp.checklistVerifieds}
					updateChecklistVerifieds={valueProp.updateChecklistVerifieds}
					isDone={valueProp.isChecklistDone}
					isOff={valueProp.isChecklistOff}
					setIsOff={valueProp.setIsChecklistOff}
				/>
			) : null}
		</OnboardingContext.Provider>
	);
};

const OnboardingProvider = ({ children }) => {
	const { authTokens } = useAuth();
	const { is_verified: isVerified = [] } = authTokens || {};
	const [checklistVerifieds, setChecklistVerifieds] = useState(isVerified);
	const [isChecklistDone, setIsChecklistDone] = useState(false);
	const [isChecklistOff, setIsChecklistOff] = useState(false);
	const updateChecklistVerifieds = useCallback(
		(newIsVerified = isVerified, isInitial = false) => {
			let checklistVerifiedsString = rawChecklistSteps.reduce(
				(acc, val) =>
					typeof val.verified === 'string' && !acc.includes(val.verified)
						? acc + val.verified
						: acc,
				''
			);
			newIsVerified.forEach((isVerifiedItem) => {
				checklistVerifiedsString = checklistVerifiedsString.replace(
					isVerifiedItem,
					''
				);
			});
			if (checklistVerifiedsString.length === 0) {
				if (!isInitial && !isChecklistOff) {
					setIsChecklistDone(true);
				} else {
					setIsChecklistOff(true);
				}
			}
			setChecklistVerifieds(newIsVerified);
		},
		[isVerified, isChecklistOff]
	);

	useEffect(() => {
		if (!authTokens) {
			setIsChecklistDone(false);
		}
	}, [authTokens]);

	const value = useMemo(
		() => ({
			checklistVerifieds,
			updateChecklistVerifieds,
			isChecklistDone,
			isChecklistOff,
			setIsChecklistOff,
		}),
		[
			checklistVerifieds,
			updateChecklistVerifieds,
			isChecklistDone,
			isChecklistOff,
		]
	);

	return (
		<OnboardingProviderContent value={value}>
			{children}
		</OnboardingProviderContent>
	);
};

export default OnboardingProvider;
