import React, {
	createContext,
	useContext,
	useCallback,
	useMemo,
	useEffect,
	useRef,
} from 'react';
import useLocalStorage from 'use-local-storage';
import axios from 'axios';
import { useOnboarding } from '../hooks/useOnboarding';
import { useAuth } from './Auth';
import { sendEvent } from '../utils/eventsService';
import { useEmbed } from './Embed';

export const WatchlistContext = createContext();

export function useWatchlist() {
	return useContext(WatchlistContext);
}

export const WatchlistProvider = (props) => {
	const { value = {}, children } = props;
	const { updateChecklistVerifieds } = useOnboarding();
	const { authTokens, setAuthTokens, getAuthHeader } = useAuth();
	const { isEmbed } = useEmbed();
	const isInitialMount = useRef(true);
	const [watchedSymbols, setWatchedSymbols] = useLocalStorage(
		'watchlistSymbols',
		[]
	);
	const [watchlists, setWatchlists] = useLocalStorage('watchlists', []);

	const companiesWatchlist = useMemo(() => {
		const myWatchlist = watchlists?.find(
			(watchlist) => watchlist.watchlist_name === 'My Watchlist'
		);
		return myWatchlist?.watched_symbols || [];
	}, [watchlists]);

	// Update Watched Symbols
	const updateWatchedSymbols = useCallback(
		(watchlistsArray) => {
			const newWatchedSymbolsSet = new Set(
				watchlistsArray
					.map((watchlist) =>
						watchlist.watched_symbols.map((company) => company.symbol)
					)
					.flat()
					.sort()
			);
			const newWatchedSymbols = Array.from(newWatchedSymbolsSet);
			if (JSON.stringify(newWatchedSymbols) !== JSON.stringify(watchedSymbols))
				setWatchedSymbols(newWatchedSymbols);
		},
		[watchedSymbols, setWatchedSymbols]
	);

	// load Watchlists
	const loadWatchlists = useCallback(
		async (setIsLoading, authHeaders = getAuthHeader()) => {
			if (!isEmbed) {
				if (setIsLoading) setIsLoading(true);
				const url = '/api/watchlist/';
				return axios
					.get(url, { headers: authHeaders })
					.then((res) => {
						updateWatchedSymbols(res.data.watchlists || []);
						return res.data.watchlists;
					})
					.finally(() => {
						if (setIsLoading) setIsLoading(false);
					});
			}
			return Promise.resolve();
		},
		[updateWatchedSymbols, getAuthHeader, isEmbed]
	);

	const handleLoadWatchlists = useCallback(
		(setIsLoading, authHeaders) =>
			loadWatchlists(setIsLoading, authHeaders).then((res) =>
				setWatchlists(res)
			),
		[loadWatchlists, setWatchlists]
	);

	const addSymbolToWatchlist = useCallback(
		async (symbol, isOnboarding = false, authHeaders = getAuthHeader()) => {
			const url = `/api/watchlist/${symbol}`;
			return axios.post(url, {}, { headers: authHeaders }).then((res) => {
				sendEvent({
					gaName: 'add_to_watchlist',
					gaParams: { symbols: [symbol], is_onboarding: isOnboarding },
					fbEvent: 'AddToWishlist',
					fbParams: {
						content_ids: [symbol],
					},
					rdtEvent: 'AddToWishlist',
					linkedInConversionId: '8183410',
				});
				setAuthTokens({
					...authTokens,
					is_verified: res.data.is_verified,
				});
				updateChecklistVerifieds(res.data.is_verified);
				handleLoadWatchlists(null, authHeaders);
				return res.data;
			});
		},
		[
			getAuthHeader,
			setAuthTokens,
			authTokens,
			updateChecklistVerifieds,
			handleLoadWatchlists,
		]
	);

	const deleteSymbolFromWatchlist = useCallback(
		async (symbol) => {
			const url = `/api/watchlist/${symbol}`;
			return axios
				.delete(url, {
					headers: getAuthHeader(),
				})
				.then(() => {
					sendEvent({
						gaName: 'remove_from_watchlist',
						gaParams: { symbols: [symbol] },
					});
					handleLoadWatchlists();
				});
		},
		[getAuthHeader, handleLoadWatchlists]
	);

	useEffect(() => {
		if (!isInitialMount.current) {
			handleLoadWatchlists();
		}
		isInitialMount.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens]);

	const newValue = useMemo(
		() => ({
			...value,
			loadWatchlists: handleLoadWatchlists,
			watchedSymbols,
			setWatchedSymbols,
			watchlists,
			setWatchlists,
			updateWatchedSymbols,
			addSymbolToWatchlist,
			deleteSymbolFromWatchlist,
			companiesWatchlist,
		}),
		[
			value,
			handleLoadWatchlists,
			watchedSymbols,
			setWatchedSymbols,
			watchlists,
			setWatchlists,
			updateWatchedSymbols,
			addSymbolToWatchlist,
			deleteSymbolFromWatchlist,
			companiesWatchlist,
		]
	);

	return (
		<WatchlistContext.Provider value={newValue}>
			{children}
		</WatchlistContext.Provider>
	);
};
