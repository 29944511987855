import React from 'react';
import Lottie from 'lottie-react';
import rocketAnimation from '../../../../lottie-animations/techny-launching-a-startup.json';

const lottieContainerStyle = { height: '240px', width: '240px' };

export default function CalculatePortfolio() {
	return (
		<>
			<div className="mx-auto" style={lottieContainerStyle}>
				<Lottie animationData={rocketAnimation} loop />
			</div>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<h2 className="text-center">Creating Your Portfolio...</h2>
				<p className="text-center">
					“Someone’s sitting in the shade today because someone planted a tree a
					long time ago.” - Warren Buffet
				</p>
			</div>
		</>
	);
}
