import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { sendClickEvent } from '../../utils/eventsService';
import { useAuth } from '../../context/Auth';
import LinkBrokerageStage from './auth-modal/signinOnboarding/LinkBrokerageStage';
import FormModal from './modals/FormModal';

export default function PreConnectPortfolioButton(props) {
	const {
		variant: buttonVariant = 'multi-animated',
		label = 'Add Portfolio',
		className = '',
		eventLocation,
		stage,
		style: buttonStyle = {},
	} = props;
	const [showModal, setShowModal] = useState(false);
	const { authTokens, setAuthModalType, setAfterOnboarding } = useAuth();

	const handleClickConnectPortfolio = useCallback(() => {
		sendClickEvent({
			element: 'portfolio_connection_explanation',
			event_location: eventLocation,
		});
		if (authTokens) {
			setShowModal(true);
		} else {
			setAuthModalType('signup');
			setAfterOnboarding(() => () => {
				setShowModal(true);
			});
		}
	}, [
		setShowModal,
		eventLocation,
		authTokens,
		setAuthModalType,
		setAfterOnboarding,
	]);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	return (
		<>
			<Button
				style={buttonStyle}
				className={className}
				onClick={handleClickConnectPortfolio}
				variant={buttonVariant}
				id={`track-element connect portfolio button${
					eventLocation ? ` on ${eventLocation}` : ''
				}`}
			>
				{label}
			</Button>
			<FormModal
				contentClassName="col-lg-6 col-sm-8 col-xs-11"
				isShow={showModal}
				onChange={handleCloseModal}
				dialogClassName="sign-modal signup-modal fullscreen"
			>
				<LinkBrokerageStage nextStage={handleCloseModal} stage={stage} />
			</FormModal>
		</>
	);
}
